import noPic from "../../assets/nopic.jpg"
import moment from "moment"
import React from "react"
import '../../css/admin.scss'
import '../../css/index.css'
import '../../css/UserInfo.css'
import '../../css/MenuKpi.css'

const UserData = (props) => {

    const hidden = "**********"

    return (
        <div className="col-md-6" style={{width: "100%"}}>
            <div className="card mb-3">
                <div className="row g-0">
                    <div className="col">
                        <div className="user-avatar w-100 h-100"
                             style={{
                                 backgroundImage: props.user.picture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + props.user.picture + "')" : "url(" + noPic + ")",
                             }}>
                        </div>
                    </div>
                    <div className="col ">
                        <div className="card-body">
                            <h5 className="card-title fw-bold">Informations personnelles</h5>
                            <p className="card-text">
                                <i className="fas fa-envelope text-secondary me-1"></i>
                                {props.showSecret ? props.user.mailAddress : hidden}
                                <br/>
                                <i className="far fa-id-card text-secondary me-1"></i> {props.user.surname} {props.user.lastname}
                                <br/>
                                <i className="fas fa-map-marker-alt text-secondary me-1"></i> {props.user.zipCode}
                                <br/>
                                <i className="fas fa-phone text-secondary me-1"></i> {props.showSecret ? props.user.phone ? props.user.phone : "N/A" : hidden}
                                <br/>
                                <i className="fa fa-birthday-cake text-secondary me-1"></i> {props.user.birthdate ? moment(props.user.birthdate).format("DD/MM") : "N/A"}
                                <br/>
                                <i className="fas fa-venus-mars text-secondary me-1"></i> {props.user.genre ? props.user.genre : "N/A"}

                            </p>

                        </div>
                    </div>
                    <div className="col col-border">
                        <div className="card-body">
                            <h5 className="card-title fw-bold">Profil</h5>
                            <p className="card-text">
                                <i className="fas fa-user text-secondary me-1"></i> {props.user.pseudo}
                                <br/>
                                <i className="fas fa-sticky-note text-secondary me-1"></i> {props.user.description ? props.user.description : "N/A"}
                                <br/>
                                <i className="fas fa-level-up-alt text-secondary me-1"></i> Niveau
                                : {props.user.level ? props.user.level : "0"}
                                <br/>
                                <i className="fas fa-level-up-alt text-secondary me-1"></i> XP
                                : {props.user.xp ? props.user.xp : "0"}

                            </p>
                        </div>
                    </div>
                    <div className="col col-border">
                        <div className="card-body">
                            <h5 className="card-title fw-bold">Autre ?</h5>
                            <p className="card-text">
                                <i className="fas fa-calendar text-secondary me-1"></i> Inscrit
                                le {moment(props.user.registrationDate).format("DD/MM/YYYY")}
                                <br/>
                                <i className="fas fa-calendar text-secondary me-1"></i> Dernière
                                connexion
                                le {moment(props.user.lastConnectionDate).format("DD/MM/YYYY")}
                                <br/>
                                <i className="fab fa-facebook-f text-secondary me-1"></i>{props.user.facebookUser ? "FaceBook utilisateur" : "Pas de FaceBook"}
                                <br/>
                                <i
                                    className="fas  text-secondary me-1"></i> {props.showSecret ? props.user.mangopayUserId ? `MangoPayID
                                            : ${props.user.mangopayUserId}` : "Pas de MangoPay id" : hidden}
                                <br/>
                                <i className="fas text-secondary me-1"></i> Validation
                                : {props.user.isValidated ? "Email vérifié" : "Email non vérifié"}
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UserData
