import React, {useEffect, useState} from "react"
import '../css/admin.scss'
import '../css/app.scss'
import '../css/UserInfo.css'
import {Link, Navigate, useSearchParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../redux/usersSlice"
import Pagination from "./Pagination"
import {Spinner} from "react-bootstrap"
import UserCard from "./UserCard";
import {getUsersByPage} from "../functions/FetchFunctions";

function Users(props) {
    const [params, setParams] = useSearchParams()

    const [users, setUsers] = useState([])
    const [lastPage, setLastPage] = useState(0)
    const [pages, setPages] = useState([1])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(1)

    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()

    useEffect(() => {
        if (params.get('page')) {
            setPage(Number(params.get('page')))
            setNextPage(Number(params.get('page')) + 1)
        }
    }, [params])

    useEffect(() => {
        setPages(Array.from({length: lastPage}, (_, i) => i + 1))
    }, [lastPage])

    useEffect(() => {
        setUsers([])
        fetchUsers().then()
    }, [page])


    const fetchUsers = async () => {
        if(document.getElementById("list-users")) {
            document.getElementById("list-users").style.display = "none"
            document.getElementById("wait-fetch").style.display = "block"
        }
        const response = await getUsersByPage(page)
        const json = await response.json()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {

            dispatch(setLogged(true))
            if (props.limite) {
                setUsers(json["hydra:member"].slice(0, props.limite))

            } else {
                setUsers(json["hydra:member"])
                setLastPage(Math.ceil(json["hydra:totalItems"] / 10))
            }
            if(document.getElementById("list-users")) {
                document.getElementById("wait-fetch").style.display = "none"
                document.getElementById("list-users").style.display = "block"
            }
        }
    }



    return (
        <div style={{margin: "5%", marginTop: "56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }
            <div className="container">
                <Pagination page={page} pages={pages} nextPage={nextPage} lastPage={lastPage} url={"/users"}/>

                <div className="card border-0 p-1 mb-3">
                    <div className="card-header bg-white text-success fw-bold">
                        <i className="fas fa-users"></i> Utilisateurs
                    </div>
                    <Spinner id={"wait-fetch"} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <div id={"list-users"} className="card-body">
                        <div className="row">
                            {users && users.map((user, index) => (
                                <div key={index} className="col-md-6">
                                    <Link style={{textDecoration: "none", color: "black"}} to={`/${user.id}`}>

                                        <UserCard user={user} />

                                    </Link>
                                </div>
                            ))}

                        </div>
                        {props.limite && (
                            <div className="float-end mt-3">
                                <p>
                                    <Link className="see" to="/users"><small className="text-muted">Tout voir <i
                                        className="fas fa-angle-right"></i></small></Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users
