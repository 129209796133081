import React, {useState} from "react"
import {Link, Navigate} from "react-router-dom"
import '../css/index.css'
import '../css/UserInfo.css'
import {useSelector} from "react-redux"
import UserCard from "./UserCard"

const Recherche = () => {
    const [form, setForm] = useState({recherche: ""})
    const [users, setUsers] = useState([])
    const logged = useSelector((state) => state.users.logged)
    const usersFetch = useSelector((state) => state.users.value)

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }))
        setUsers(usersFetch)
        if (value.length === 0) {
            setUsers([])
        }
    }

    return (
        <div style={{marginTop: "56px"}}>

            {
                !logged && (
                    <Navigate to={"/login"}/>
                )
            }

            <div className="container">
                <div className="input-group">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form1">Recherche</label>

                        <input type="search"
                               id="form1"
                               name="recherche"
                               required="required"
                               className="form-control"
                               value={form.recherche}
                               placeholder="Rechercher un utilisateur"
                               onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="card border-0 p-1 mb-3">

                    <div className="card-body">
                        <div className="row">
                            {
                                users && users.filter(user => user.pseudo.toLowerCase().includes(form.recherche.toLowerCase())).map((user, index) => (
                                    <div key={index} className="col-md-6">
                                        <Link style={{textDecoration: "none", color: "black"}} to={`/${user.id}`}>
                                            <UserCard user={user} />

                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recherche
