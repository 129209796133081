import React, {useEffect, useState} from 'react'
import {getCookie} from "react-use-cookie"
import '../css/index.css'
import '../css/Login.scss'
import {Navigate} from "react-router-dom"
import moment from "moment"
import jwtDecode from "jwt-decode"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../redux/usersSlice"
import {hasPermissionsTo} from "../functions/rolesFunctions";
import ModalConfirmNotif from "./ModalConfirmNotif";
import {createGlobalNotification} from "../functions/FetchFunctions";

const Notification = () => {
    const [form, setForm] = useState({title: '', content: ''})
    const [messageSent, setMessageSent] = useState(false)
    const [messageError, setError] = useState(false)
    const [forbidden, setForbidden] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const dispatch = useDispatch()
    const logged = useSelector((state) => state.users.logged)

    useEffect(()=>{
        if(document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles) {
            //vérifier si le token dans le cookies est expiré
            let date = new Date("01/01/1970")
            let seconds = jwtDecode(getCookie('token')).exp
            date.setSeconds(date.getSeconds() + seconds)
            if (moment().isAfter(date)) {
                dispatch(setLogged(false))
            } else if (!hasPermissionsTo("ROLE_NOTIFICATION")) {
                setForbidden(true)
            }
        }
    },[])


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setShowConfirm(true)
    }

    const sendNotification = async () => {
        const notificationResponse = await createGlobalNotification(form)
        if (notificationResponse.status === 401) {
            setLogged(false)
        }
        if (notificationResponse.status === 201) {
            setMessageSent(true)
            setForm({title: '', content: ''})
        } else {
            setError(true)
        }
    }

    return (
        <div className="login-content rounded  bg-white m-auto notification">
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }

            {
                forbidden ? (
                    <div className={"container"} style={{marginTop: "60px"}}>
                        <p>Vous n'avez pas accès à cette page</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div className="bg-white p-3">
                        <h2 className="text-bopper mb-3">Création d'une notification globale</h2>
                        <form onSubmit={handleSubmit} method="post">
                            <div className="form-group">
                                <label htmlFor="inputTitle" className="fw-bold">Titre</label>
                                <input className={"form-control"}
                                       type={'text'}
                                       name={'title'}
                                       placeholder={'Titre'}
                                       value={form.title}
                                       onChange={handleChange}
                                       required
                                />
                                <label htmlFor="contentTitle" className="mt-3 fw-bold">Contenu</label>
                                <textarea className={"form-control"}
                                          name={'content'}
                                          placeholder={'Contenu de la notification'}
                                          value={form.content}
                                          onChange={handleChange}
                                          required
                                />
                            </div>

                            <div className="d-flex w-100">
                                <button className="ms-auto mt-3 btn btn-bopper" type="submit">
                                    Envoyer
                                </button>
                            </div>
                        </form>

                        {messageSent && (
                            <div>
                                <p style={{color: "green"}}>La notification a bien été envoyée</p>
                            </div>)
                        }

                        {messageError && (
                            <div>
                                <p style={{color: "red"}}>
                                    Votre notification n'a pas été envoyée
                                </p>
                            </div>
                        )}
                    </div>
                )
            }

            <ModalConfirmNotif
                isVisible={showConfirm}
                setVisible={setShowConfirm}
                titre={form.title}
                contenu={form.content}
                sendNotification={sendNotification}
            />
        </div>
    )
}

export default Notification
