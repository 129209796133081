import React, {useEffect, useState} from "react"
import {Carousel} from "react-bootstrap"
import moment from "moment"
import {useDispatch} from "react-redux"
import {setLogged} from "../../redux/usersSlice"
import '../../css/admin.scss'
import '../../css/index.css'
import '../../css/UserInfo.css'
import '../../css/MenuKpi.css'
import {deletePostById, getPostById, getUserPosts} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const Posts = (props) => {

    const dispatch = useDispatch()

    const [posts, setPosts] = useState([])
    const [postDeleteId, setPostDeleteId] = useState(0)
    const [showingPostModal, setShowPostModal] = useState(false)
    const [didSupress, setDidSupress] = useState(false)
    const [showAlertPost, setShowAlertPost] = useState(false)
    const [postState, setPostState] = useState({
        postShown: {},
        postPictures: [],
        hashtags: []
    })

    useEffect(() => {
        getAllUserPost().then(() => {
        })
    }, [didSupress])

    useEffect(() => {
        const close = (e) => {
            if (e.key === "Escape") {
                setShowAlertPost(false)
                setShowPostModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const getAllUserPost = async () => {
        const response = await getUserPosts(props.user.id)
        if (response.status === 200) {
            const json = await response.json()
            setPosts(json["userPosts"])
        }
    }

    const findPostPictures = async (postId) => {
        const response = await getPostById(postId)
        if (response.status === 200) {
            dispatch(setLogged(true))
            const json = await response.json()
            setPostState({
                postShown: json,
                postPictures: json["postPictures"],
                hashtags: json["posTags"]
            })
        }
    }

    const ConfirAlertPost = (props) => (
        <div className={" bg-modal "}>
            <div className={"card mb-3 alert-modal"}>
                <h5 style={{marginTop: "56px"}}>Êtes-vous sur de vouloir supprimer ce post ?</h5>
                <p>Cette action est irreversible</p>

                <button onClick={async () => {
                    await deletePostById(props.post)
                    setDidSupress(!didSupress)
                    setShowAlertPost(false)
                }}
                        className="btn btn-danger btn-alert-modal">Supprimer
                </button>
                <div className="col-sm ">
                    <a href="#" className={"closebtn"} onClick={(event) => {
                        event.preventDefault()
                        setShowAlertPost(false)
                    }}>&times;</a>
                </div>
            </div>
        </div>
    )


    const PostModal = () => (
        <div className={" bg-modal "}>

            <div className="card mb-3 car-modal">
                <div className="row g-0" style={{height: "100%"}}>
                    <div className="col-lg-4">
                        <div className="user-avatar w-100 h-100">
                            <Carousel>
                                {
                                    postState.postPictures && postState.postPictures.map((pic, index) => (
                                        <Carousel.Item key={index}>
                                            <img
                                                className="d-block w-100"
                                                src={`${process.env.REACT_APP_ADDRESS}img/avatar/${pic.picture}`}
                                                alt="voiture"
                                            />
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-lg-8">

                        <div className="card-body" style={{color: "black"}}>
                            <h5 className="card-title fw-bold">Titre du poste : {postState.postShown.modelName}</h5>
                            <br/>
                            {postState.postShown.description && (
                                <p className="card-text">
                                    Description : <br/>
                                    {postState.postShown.description}
                                </p>
                            )}
                            <br/>
                            {postState.hashtags.length > 0 && (<div>Tags : <br/></div>)}
                            <div className="card-text" style={{display: "flex", flexDirection: "row"}}>

                                {
                                    postState.hashtags && postState.hashtags.map((tag, index) => (
                                        <p className={"tags"} key={index}>
                                            #{tag.tag.name}&nbsp;
                                        </p>
                                    ))
                                }
                            </div>
                            <p className="card-text"><small className="text-muted">Posté
                                le {moment(postState.postShown.creationDate).format("DD/MM/YYYY")}</small>
                            </p>

                        </div>
                    </div>
                    <div className="col-sm ">
                        <a href="#" className={"closebtn"} onClick={(event) => {
                            event.preventDefault()
                            setShowPostModal(false)
                        }}>&times;</a>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div id={"posts"} className="card border-0 p-1 mb-3">


            {
                showingPostModal && <PostModal/>
            }


            {
                showAlertPost && <ConfirAlertPost post={postDeleteId}/>
            }


            <div className="card-body">
                <div className="row">
                    <h3>POSTS {posts && `: ${posts.length}`}</h3>
                    {posts.map((p, index) => (
                        <div key={index} className="col-md-6">
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="card-body">
                                        <div className={"row"} style={{width: "100%"}}>
                                            <h5 className="card-title fw-bold"
                                                style={{width: "50%", cursor: "pointer"}}
                                                onClick={() => {
                                                    findPostPictures(p.id).then(() => setShowPostModal(true))

                                                }}>{p.modelName}</h5>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "right",
                                                alignItems: "flex-end",
                                                width: "50%"
                                            }}>

                                                {document.cookie.length !== 0 &&  hasPermissionsTo("ROLE_MODERATION") && (

                                                    <button type="button"
                                                            style={{
                                                                width: "fit-content",
                                                                marginBottom: "1%"
                                                            }}
                                                            onClick={() => {
                                                                setPostDeleteId(p.id)
                                                                setShowAlertPost(true)
                                                            }}
                                                            className="btn btn-danger">&times;</button>)}

                                            </div>
                                        </div>
                                        <p className="card-text">
                                            {p.description}
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>

                    ))}
                    {posts.length === 0 && (
                        <p className="text-muted">Cet utilisateur n'a encore rien posté.</p>
                    )}
                </div>
            </div>

        </div>
    )
}

export default Posts
