import React, {useEffect, useState} from "react"
import '../../css/admin.scss'
import '../../css/index.css'
import '../../css/UserInfo.css'
import '../../css/MenuKpi.css'
import {Navigate, useParams} from "react-router-dom"
import {HashLink as Link} from 'react-router-hash-link'
import {useSelector} from "react-redux"
import Garage from "./Garage"
import UserData from "./UserData"
import Commentaires from "./Commentaires"
import Posts from "./Posts"
import {Spinner} from "react-bootstrap"
import NavbarUser from "./NavbarUser"
import {updateRoles} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";
import {getCookie} from "react-use-cookie";

const UserInfo = () => {
    const {userId} = useParams()
    const [form, setForm] = useState({secretCodeEnter: ""})
    const [user, setUser] = useState({})

    const logged = useSelector((state) => state.users.logged)

    const [notFound, setNotFound] = useState(false)

    const users = useSelector((state) => state.users.value)

    const [showSecret, setShowSecret] = useState(false)
    const [showSecretModal, setShowSecretModal] = useState(false)
    const [showConfirmAdmin, setShowConfirmAdmin] = useState(false)

    const [isSuperAdminConnected, setIsSuperAdminConnected] = useState(false)

    useEffect(() => {
        if (document.cookie.length !== 0 && hasPermissionsTo("ROLE_SUPER_ADMIN")) {
            setIsSuperAdminConnected(true)
        }
    }, [])

    useEffect(() => {
        const close = (e) => {
            if (e.key === "Escape") {
                setShowSecretModal(false)
                setShowConfirmAdmin(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    useEffect(() => {
        fetchUser()
    }, [userId])


    const handleChange = (event) => {
        event.preventDefault()
        const value = event.target.value
        setForm({secretCodeEnter: value})
    }


    const fetchUser = () => {
        if (document.getElementById("info-user")) {
            document.getElementById("info-user").style.display = "none"
            document.getElementById("wait-fetch").style.display = "block"
        }
        // if (users.length > 0) {
        //     //si l'utilisateur est dans la liste
        //     if (users.filter(u => u.id == userId).length > 0) {
        //         setUser(users.filter(u => u.id == userId)[0])
        //         document.getElementById("wait-fetch").style.display = "none"
        //         document.getElementById("info-user").style.display = "block"
        //         setNotFound(false)
        //     } else {
        //         setNotFound(true)
        //     }
        // }
        if (userId) {
            fetch(`${process.env.REACT_APP_API_ADDRESS}users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${getCookie('token')}`
                }
            })
                .then(r => r.json())
                .then(u => {
                    setUser(u);
                    document.getElementById("wait-fetch").style.display = "none"
                    document.getElementById("info-user").style.display = "block"
                    setNotFound(false)
                })
        } else {
            setNotFound(true)
        }
    }

    const verifyCode = (event) => {
        event.preventDefault()
        if (form.secretCodeEnter === process.env.REACT_APP_SECRET_CODE) {
            setShowSecret(true)
            setShowSecretModal(false)
        }
        setForm({secretCodeEnter: ""})
    }

    const ConfirmSetAdmin = () => (
        <div className={" bg-modal "}>
            <div className={"card mb-3 alert-modal"}>
                <h5 style={{marginTop: "56px"}}>Êtes-vous sûr de vouloir lui donner ce rôle ?</h5>

                <button onClick={async () => {
                    await updateRoles(userId, ["ROLE_USER", "ROLE_ADMIN"])
                    setShowConfirmAdmin(false)
                }}
                        className="btn btn-warning btn-alert-modal">Donner les droits
                </button>
                <div className="col-sm ">
                    <a href="#" className={"closebtn"} onClick={(event) => {
                        event.preventDefault()
                        setShowConfirmAdmin(false)
                    }}>&times;</a>
                </div>
            </div>
        </div>
    )


    return (

        <div style={{marginTop: "100px"}}>

            {!logged && (<Navigate to={"/login"}/>)}

            {notFound ? (<div className="container">
                <p>
                    <small className="text-muted">Aucun utilisateur trouvé.</small>
                    <br/>
                    <Link className="see" to="/users"><small className="text-muted">Retourner à la page
                        utilisateur <i className="fas fa-angle-right"></i></small></Link>
                </p>
            </div>) : (


                <div>

                    {showConfirmAdmin && <ConfirmSetAdmin/>}

                    <NavbarUser/>

                    <div className={"container "}>
                        <div id="info-user" className={"row"}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: "1%"
                            }}>
                                <h3>INFO DE L'UTILISATEUR
                                    : {user.company ? "PROFESSIONNEL" : "PARTICULIER"}</h3>
                                <div>
                                    <i style={{cursor: "pointer"}}
                                       className={!showSecret ? "fas fa-eye-slash p-3 me-1 fa-lg" : "fas fa-eye fa-lg p-3 me-1"}
                                       onClick={() => {
                                           if (!showSecret) {
                                               setShowSecretModal(true)
                                           } else {
                                               setShowSecret(false)
                                           }
                                       }}></i>
                                    {
                                        document.cookie.length !== 0 && hasPermissionsTo("ROLE_CHAT") && (
                                            <Link to={"/chat?pseudo=" + user.pseudo}>
                                                <button type="button" className="btn btn-primary">
                                                    Envoyer un message
                                                </button>
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                            {showSecretModal && (<div className={"card mb-3 secret-modal p-3"}>
                                <h6>Entrez le code secret pour avoir accès à ces
                                    données</h6>

                                <form className={"input-secret m-3"} key={"hide"} onSubmit={verifyCode}>

                                    <input name={"secretCodeEnter"}
                                           type={"password"}
                                           id={"form1"}
                                           required="required"
                                           className="form-control"
                                           value={form.secretCodeEnter}
                                           onChange={handleChange}
                                    />

                                    <input type="submit" hidden={true}/>
                                </form>

                                <a href="#" className={"closebtn"}
                                   onClick={(event) => {
                                       event.preventDefault()
                                       setShowSecretModal(false)
                                   }}>&times;</a>

                            </div>)}
                        </div>


                        <Spinner id={"wait-fetch"} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        {user.id && (
                            <div id={"info-user"}>
                                <UserData user={user} showSecret={showSecret}/>

                                {isSuperAdminConnected && (
                                    <button className={"btn btn-primary"} onClick={() => {
                                        setShowConfirmAdmin(true)
                                    }}>Donner le rôle administrateur</button>
                                )}

                                <Garage user={user}/>

                                <Commentaires user={user}/>

                                <Posts user={user}/>
                            </div>)}

                        {/* <div className={"container"} style={{width: 'fit-content', height: "fit-content"}}>
                    <button type="button" className="btn btn-danger">SUPPRIMER LE COMPTE
                    </button>
                </div>*/}
                    </div>
                </div>)}
        </div>)
}
export default UserInfo
