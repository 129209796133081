import {getCookie} from "react-use-cookie";

export const fetchAllUsers = () => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}users/all`, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const userLogin = (form) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'login_check', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: form.email,
            password: form.password
        })
    })
}

export const getBugs = (page) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'reports?page=' + page, {
        headers: {
            Authorization: "Bearer " + getCookie('token')
        }
    })
}

export const getReports = (page) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'reports?page=' + page, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getTestDrives = (page) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'test_drives?page=' + page, {
        headers: {
            Authorization: "Bearer " + getCookie('token')
        }
    })
}

export const updateRoles = (userId, roles) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}users/${userId}/roles`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie('token')}`
        },
        body: JSON.stringify({
            roles: roles
        })
    })
}

export const getUserComments = (userId) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}users/${userId}/comments`, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const deleteCommentById = (id) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}comments/${id}`, {
        method: "delete",
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getUserPosts = (userId) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}users/${userId}/posts`, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const deletePostById = (id) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}posts/${id}`, {
        method: "delete",
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getPostById = (id) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}posts/${id}`, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getCarsNumberPerModel = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'cars/per_model', {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getAllTestDrives = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'all_test_drive', {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getCarsNumberPerBrand = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'cars/per_brand', {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getNbTestDrivesPerMonth = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'test_drives/nb_test_drive_per_month', {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getAllPosts = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'posts', {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}

export const getAllComments = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'comments', {
        headers: {
            Authorization: "Bearer " + getCookie('token')
        }
    })
}

export const getAllLikes = () => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'likes', {
        headers: {
            Authorization: "Bearer " + getCookie('token')
        }
    })
}

export const createGlobalNotification = (form) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'users/notification', {
        method: "post",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('token')
        },
        body: JSON.stringify({
            title: form.title,
            content: form.content
        })
    })
}

export const createDiscussion = (participantId) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}discussions/create`, {
        method: 'POST', headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + getCookie('token'),
            'Content-Type': 'application/json'
        }, body: JSON.stringify({
            participants: [participantId]
        })
    })
}

export const getLastDiscussions = (page) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}discussions/last?page=${page}`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`
        }
    })
}

export const seeDiscussion = (id) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}discussion_seens/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getCookie('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            seen: true
        })
    })
}

export const createDiscussionMessage = (message, discussionId) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}messages/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + getCookie('token'),
            'Content-Type': 'application/json'
        }, body: JSON.stringify({
            message: message,
            discussion_id: discussionId
        })
    })
}

export const getDiscussionSeensStatus = () => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}discussion_seens/status`, {
        headers: {
            Authorization: `Bearer ${getCookie('token')}`
        }
    })
}

export const getUsersByPage = (page) => {
    return fetch(process.env.REACT_APP_API_ADDRESS + 'users?page=' + page, {
        headers: {
            Authorization: 'Bearer ' + getCookie('token')
        }
    })
}
