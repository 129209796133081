import React, {useEffect, useState} from "react"
import {Link, Navigate, useSearchParams} from "react-router-dom"
import moment from "moment"
import '../css/UserInfo.css'
import Pagination from "./Pagination"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../redux/usersSlice"
import {Spinner} from "react-bootstrap"
import {getReports} from "../functions/FetchFunctions";

function Reports(props) {

    const [params, setParams] = useSearchParams()

    const [reports, setReports] = useState([])
    const [lastPage, setLastPage] = useState(0)
    const [pages, setPages] = useState([])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(1)

    const logged = useSelector((state)=> state.users.logged)
    const dispatch = useDispatch()

    const fetchReports = async () => {
        if(document.getElementById("list-reports")) {
            document.getElementById("list-reports").style.display = "none"
            document.getElementById("wait-fetch-reports").style.display = "block"
        }
        const response = await getReports(page)
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            dispatch(setLogged(true))
            const json = await response.json()
            if (props.limite) {
                setReports(json["hydra:member"].filter(r => r.subject !== "Bug").slice(0, props.limite))
            }
            else {
                const reports = json["hydra:member"].filter(r => r.subject !== "Bug")
                setReports(reports)
                setLastPage(1)
            }
        }
    }

    useEffect(() => {
        if (params.get('page')) {
            setPage(Number(params.get('page')))
            setNextPage(Number(params.get('page')) + 1)
        }
    }, [params])

    useEffect(() => {
        setPages(Array.from({length: lastPage}, (_, i) => i + 1))
    }, [lastPage])

    useEffect(() => {
        setReports([])
        fetchReports().then(()=>{
            if(document.getElementById("list-reports")) {
                document.getElementById("wait-fetch-reports").style.display = "none"
                document.getElementById("list-reports").style.display = "block"
            }
        })
    }, [page])


    return (
        <div style={{margin: "5%", marginTop:"56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }
            <div className="container">
                <Pagination page={page} pages={pages} nextPage={nextPage} lastPage={lastPage} url={"/reports"}/>
                <div className="card border-0 p-1 mb-3">
                    <div className="card-header bg-white text-warning fw-bold">
                        <i className="fas fa-flag"/> Signalements
                    </div>
                    <Spinner id={"wait-fetch-reports"} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <div id={"list-reports"} className="card-body">
                        {reports && reports.map((report,index) => (
                                <div key={index} className="d-flex mb-3">
                                    <div className="rect user-avatar"
                                         style={{
                                             backgroundImage: report.user.picture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + report.user.picture + "')" : "url('img/nopic.jpg')",
                                         }}/>
                                    <div className="notif-content flex-grow-1 p-1 ps-2 pe-2">
                                        <p>
                                            <b><Link  className={"text-reset text-decoration-none"} to={"/"+report.user.id}>{report.user.pseudo}</Link></b> a
                                            signalé <b><Link  className={"text-reset text-decoration-none"} to={"/"+report.reported.id}>{report.reported.pseudo}</Link></b> pour <b>{report.subject}</b>.<br/>
                                            {report.comment && (<span className="fst-italic">“{report.comment}”</span>)}
                                        </p>
                                        <p className="mobile-date"><small
                                            className="text-muted">Le {moment(report.date).format("DD/MM/YYYY")}</small>
                                        </p>
                                    </div>
                                    <div className="top-right-date float-end text-muted">
                                        <small>Le {moment(report.date).format("DD/MM/YYYY")}</small>
                                    </div>
                                </div>
                            )
                        )}
                        {reports.length === 0 && (
                            <p className="text-muted">Aucun signalement n'a été enregistré pour le moment.</p>
                        )}
                        {props.limite && (
                            <div className="float-end mt-3">
                                <p>
                                    <Link className="see" to="/reports"><small className="text-muted">Tout voir <i className="fas fa-angle-right"/></small></Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
