import {HashLink as Link} from "react-router-hash-link";
import logo from "../../assets/bopper-min.png";
import React from "react";

const NavbarUser = () => {

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "300px"
    }

    return (
        <nav className="navbar fixed-top navbar-dark bg-dark">
            <div className="container-fluid">
                <span className="navbar-brand" style={{position: "fixed", left: "10px", zIndex: 1}}
                      onClick={openNav}>
                    <i className={"fa fa-bars"}/>
                </span>

                <Link className="navbar-brand" to="/" style={{marginLeft: "35px"}}>
                    <img src={logo} alt="Bopper" height="25"/>
                </Link>
                <Link className="navbar-brand" to="#info-user">
                    INFO DE L'UTILISATEURS
                </Link>
                <Link className="navbar-brand" to="#garage">
                    GARAGE
                </Link>
                <Link className="navbar-brand" to="#commentaires">
                    COMMENTAIRES
                </Link>
                <Link className="navbar-brand" to="#posts">
                    POSTS
                </Link>

                <div className="float-end powered">
                    <small className="text-light"><i className="fas fa-rocket me-1"></i> Powered
                        by <a
                            href={"https://vitalytech.fr"} target={"_blank"}>Vitalytech</a></small>
                </div>
            </div>
        </nav>
    )
}
export default NavbarUser