import React, {useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import logo from '../assets/bopper-min.png'
import {NavDropdown} from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import {getCookie} from "react-use-cookie"
import jwtDecode from "jwt-decode"
import moment from "moment"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../redux/usersSlice"
import {getDiscussionSeensStatus} from "../functions/FetchFunctions";
import {hasPermissionsTo} from "../functions/rolesFunctions";
import UserContext from "../context/UserContext";

const Navbar = () => {
    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()
    const {connectedUser} = useContext(UserContext)

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "300px"
    }

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0"
    }

    useEffect(() => {
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles) {

            let date = new Date("01/01/1970")
            let seconds = jwtDecode(getCookie('token')).exp
            date.setSeconds(date.getSeconds() + seconds)
            if (moment().isAfter(date)) {
                dispatch(setLogged(false))
            }else {
                discuNotSeen().then()
            }
        }
    }, [])


    const discuNotSeen = async () => {
        const res = await getDiscussionSeensStatus()
        if (res.status === 200) {
            const json = await res.json()
            dispatch(setLogged(true))
            if  (document.getElementById("notif-circle")) {
                document.getElementById("notif-circle").style.display = json["hydra:totalItems"] > 0 ? "block" : "none"
                document.getElementById("notif-circle2").style.display = json["hydra:totalItems"] > 0 ? "block" : "none"
            }
        } else if (res.status === 401) {
            dispatch(setLogged(false))
            document.getElementById("notif-circle").style.display = "none"
            document.getElementById("notif-circle2").style.display = "none"
        }
    }


    return (
        <div>
            <nav className="navbar fixed-top navbar-dark bg-dark">
                <div className="container-fluid">

                        <span className="navbar-brand" style={{position: "fixed", left: "10px", zIndex: 1}}
                              onClick={() => {
                                  if (logged) openNav()
                              }}>
                        <i className={"fa fa-bars"}/>

                            {

                                document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles && hasPermissionsTo("ROLE_CHAT") && (
                                    <i id={"notif-circle2"} className={"fas fa-circle fa-xs"}
                                       style={{
                                           position: "absolute",
                                           top: 0,
                                           right: "-7px",
                                           zIndex: 1,
                                           height: "2px",
                                           color: "#29abe2",
                                       }}/>
                                )
                            }
                        </span>

                    <Link className="navbar-brand" to="/" style={{marginLeft: "35px"}}>
                        <img src={logo} alt="Bopper" height="25" onClick={closeNav}/>
                    </Link>

                    <div className="float-end powered">
                        <small className="text-light"><i className="fas fa-rocket me-1"></i> Powered by <a
                            href={"https://vitalytech.fr"} target={"_blank"}>Vitalytech</a></small>
                    </div>
                </div>


            </nav>

            {logged && (<div>
                <div id="mySidenav" className="sidenav"
                     style={{marginTop: "56px"}}>



                    <a href="#" className="closebtn" onClick={(event) => {
                        event.preventDefault()
                        closeNav()
                    }}>&times;</a>

                    <div>
                        <p className={'text-center text-light'}>{connectedUser?.username}</p>
                    </div>

                    {
                        document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  hasPermissionsTo("ROLE_KPI") && (
                            <NavDropdown id="nav-dropdown-dark-example" className={"navbar-brand"} title="KPI"
                                         menuVariant="dark">

                                <DropdownItem as={Link} to="/kpi" onClick={closeNav}>Toutes les données</DropdownItem>
                                <DropdownItem as={Link} to="/kpi/users" onClick={closeNav}>Utilisateurs</DropdownItem>
                                <DropdownItem as={Link} to="/kpi/test-drives" onClick={closeNav}>Test Drive</DropdownItem>
                                <DropdownItem as={Link} to="/kpi/reports" onClick={closeNav}>Signalements</DropdownItem>
                                <DropdownItem as={Link} to="/kpi/posts" onClick={closeNav}>Posts</DropdownItem>
                            </NavDropdown>
                        )
                    }

                    {
                        document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  hasPermissionsTo("ROLE_NOTIFICATION") && (
                            <Link className="navbar-brand" to={"/notification"} onClick={closeNav}>Notification</Link>
                        )
                    }

                    <Link className="navbar-brand" to={"/recherche"} onClick={closeNav}>Recherche</Link>

                    {
                        document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  hasPermissionsTo("ROLE_CHAT") && (
                            <Link className="navbar-brand" to={"/chat"} onClick={closeNav}>
                                <div
                                    style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>Chat <i
                                    id={"notif-circle"} className={"fas fa-circle fa-xs"}
                                    style={{
                                        color: "#29abe2",
                                    }}/></div>
                            </Link>
                        )
                    }

                    {
                        document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  hasPermissionsTo("ROLE_SUPER_ADMIN") && (
                            <Link className="navbar-brand" to={"/gestion"} onClick={closeNav}>Gestion des rôles</Link>
                        )
                    }

                    <Link className="navbar-brand" style={{ position: 'absolute', bottom: 100 }} to="/logout">
                        Déconnexion
                    </Link>

                </div>

            </div>)
            }
        </div>
    )
}

export default Navbar
