import React, {useEffect, useState} from "react"
import '../../css/admin.scss'
import '../../css/index.css'
import '../../css/UserInfo.css'
import '../../css/MenuKpi.css'
import {deleteCommentById, getUserComments} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const Commentaires = (props) => {

    const [showAlertComment, setShowAlertComment] = useState(false)
    const [commentDeleteId, setCommentDeleteId] = useState(0)
    const [didSupress, setDidSupress] = useState(false)
    const [comments, setComments] = useState([])

    useEffect(() => {
        const close = (e) => {
            if (e.key === "Escape") {
                setShowAlertComment(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    useEffect(() => {
        fetchUserComments().then(() => {
        })
    }, [didSupress])

    const fetchUserComments = async () => {
        const response = await getUserComments(props.user.id)
        if (response.status === 200) {
            const json = await response.json()
            setComments(json["usersComments"])
        }
    }

    const ConfirAlertComment = (props) => (
        <div className={" bg-modal "}>
            <div className={"card mb-3 alert-modal"}>
                <h5 style={{marginTop: "56px"}}>Êtes-vous sur de vouloir supprimer ce commentaire ?</h5>
                <p>Cette action est irreversible</p>

                <button onClick={async () => {
                    await deleteCommentById(props.comment)
                    setDidSupress(!didSupress)
                    setShowAlertComment(false)
                }}
                        className="btn btn-danger btn-alert-modal">Supprimer
                </button>
                <div className="col-sm ">
                    <a href="#" className={"closebtn"} onClick={(event) => {
                        event.preventDefault()
                        setShowAlertComment(false)
                    }}>&times;</a>
                </div>
            </div>
        </div>
    )

    return (
        <div id={"commentaires"} className="card border-0 p-1 mb-3">

            {
                showAlertComment && <ConfirAlertComment comment={commentDeleteId}/>
            }

            <div className="card-body">
                <div className="row">
                    <h3>COMMENTAIRES {comments && `: ${comments.length}`}</h3>
                    {comments.map((c, index) => (
                        <div key={index} className="col-md-6">

                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold"></h5>
                                        <div className={"row"} style={{width: "100%"}}>
                                            <p style={{width: "50%"}}>
                                                {c.comment}<br/>
                                            </p>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "right",
                                                alignItems: "flex-end",
                                                width: "50%"
                                            }}>


                                                {
                                                    document.cookie.length !== 0 && hasPermissionsTo("ROLE_MODERATION") && (
                                                        <button type="button"
                                                                style={{
                                                                    width: "fit-content",
                                                                    marginBottom: "1%"
                                                                }}
                                                                onClick={() => {
                                                                    setCommentDeleteId(c.id)
                                                                    setShowAlertComment(true)
                                                                }}
                                                                className="btn btn-danger">&times;</button>
                                                    )
                                                }


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                    {comments.length === 0 && (
                        <p className="text-muted">Cet utilisateur n'a encore posté aucun
                            commentaire.</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Commentaires
