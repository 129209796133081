import React, {useEffect, useState} from "react"
import {Navigate} from "react-router-dom"
import KPIUsers from "./KPIUsers"
import KPIReport from "./KPIReport"
import KPIPost from "./KPIPost"
import KPITestDrive from "./KPITestDrive"
import '../../css/Kpi.css'
import '../../css/MenuKpi.css'
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const KPI = () => {

    const [forbidden, setForbidden] = useState(false)

    useEffect(() => {
        if (document.cookie.length !== 0 &&  !hasPermissionsTo("ROLE_KPI")) {
            setForbidden(true)
        }
    }, [])

    return (
        <div className={"container"} style={{marginTop: "56px"}}>
            {
                forbidden ? (
                    <div><p>Vous n'avez pas accès à cette page.</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (

                    <div id={"kpi"} style={{width: "100%", marginTop: "100px"}}>

                        {<KPIUsers/>}

                        {<KPITestDrive/>}

                        {<KPIReport/>}

                        {<KPIPost/>}

                    </div>

                )
            }
        </div>
    )
}

export default KPI
