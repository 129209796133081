import React, {useEffect, useState} from "react"
import {Link, Navigate, useSearchParams} from "react-router-dom"
import '../css/UserInfo.css'
import moment from "moment"
import Pagination from "./Pagination"
import {setLogged} from "../redux/usersSlice"
import {useDispatch, useSelector} from "react-redux"
import {Spinner} from "react-bootstrap"
import {getTestDrives} from "../functions/FetchFunctions";

function TestDrives(props) {

    const [params, setParams] = useSearchParams()

    const [testDrives, setTestDrives] = useState([])
    const [lastPage, setLastPage] = useState(0)
    const [pages, setPages] = useState([])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(1)

    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()

    useEffect(() => {
        if (params.get('page')) {
            setPage(Number(params.get('page')))
            setNextPage(Number(params.get('page')) + 1)
        }
    }, [params])

    useEffect(() => {
        setPages(Array.from({length: lastPage}, (_, i) => i + 1))
    }, [lastPage])

    useEffect(() => {
        setTestDrives([])
        fetchTestDrives().then(() => {
            if (document.getElementById("list-test-drives")) {
                document.getElementById("wait-fetch-td").style.display = "none"
                document.getElementById("list-test-drives").style.display = "block"
            }
        })
    }, [page])


    const fetchTestDrives = async () => {
        if (document.getElementById("list-test-drives")) {
            document.getElementById("list-test-drives").style.display = "none"
            document.getElementById("wait-fetch-td").style.display = "block"
        }
        const response = await getTestDrives(page)
        const json = await response.json()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            dispatch(setLogged(true))

            if (props.limite) {
                setTestDrives(json["hydra:member"].slice(0, props.limite))
            } else {
                setTestDrives(json["hydra:member"])
                setLastPage(Math.ceil(json["hydra:totalItems"] / 10))
            }
        }
    }


    return (
        <div style={{margin: "5%", marginTop: "56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }
            <div className="container">
                <Pagination page={page} pages={pages} nextPage={nextPage} lastPage={lastPage} url={"/test-drives"}/>
                <div className="card border-0 p-1 mb-3">
                    <div className="card-header bg-white text-bopper fw-bold">
                        <i className="fas fa-car"/> Test-Drives
                    </div>
                    <Spinner id={"wait-fetch-td"} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <div id={"list-test-drives"} className="card-body">
                        {testDrives && testDrives.length > 0 && testDrives.map((td, index) => (
                            <div key={index} className="d-flex mb-3">
                                <div className="rect user-avatar"
                                     style={{
                                         backgroundImage: td.formula.car.carPictures[0] ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + td.formula.car.carPictures[0].url + "')" : "url('img/nopic.jpg')",
                                     }}/>
                                <div className="notif-content flex-grow-1 p-1 ps-2 pe-2">
                                    <p>
                                        <b><Link  className={"text-reset text-decoration-none"}
                                                 to={"/" + td.renter.id}>{td.renter.pseudo}</Link></b> a demandé à
                                        tester <b>{td.formula.car.title}</b> de <b><Link
                                                                                         className={"text-reset text-decoration-none"}
                                                                                         to={"/" + td.formula.car.owner.id}>{td.formula.car.owner.pseudo}</Link></b> le {moment(td.creationDate).format("DD/MM/YYYY")}.<br/>
                                        <small
                                            className="text-muted">Le {moment(td.creationDate).format("DD/MM/YYYY")}</small>
                                    </p>
                                </div>
                            </div>
                        ))}
                        {testDrives.length === 0 && (
                            <p className="text-muted">Aucun Test-Drive n'a été créé pour le moment.</p>
                        )}
                        {props.limite && (
                            <div className="float-end mt-3">
                                <p>
                                    <Link className="see" to="/test-drives"><small className="text-muted">Tout voir <i className="fas fa-angle-right"/></small></Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestDrives
