import {createSlice} from '@reduxjs/toolkit'

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        value: [],
        logged: true,
        mercureToken: null
    },
    reducers: {
        setAllUsers: (state, action) => {
          state.value = action.payload
        },
        setLogged: (state, action)=>{
            state.logged = action.payload
        },
        setMercureToken: (state, action) => {
            state.mercureToken = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setAllUsers, setLogged, setMercureToken } = usersSlice.actions

export default usersSlice.reducer
