import React from "react"
import {Link} from "react-router-dom";

const Pagination = (props) => {

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                {props.page > 1 && (
                    <li className="page-item">
                        <Link className="page-link" to={props.url + "?page=" + (props.page - 1)}
                           aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                    </li>
                )}

                {props.pages && props.pages.map((currentPage, index) => (
                    <li key={index} className={props.page === currentPage ? "page-item active" : "page-item"}>
                        <Link className="page-link" to={props.url + "?page=" + currentPage}>{currentPage}</Link>
                    </li>
                ))}

                {props.page < props.lastPage && (
                    <li className="page-item">
                        <Link className="page-link" to={props.url + "?page=" + props.nextPage}
                           aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                    </li>
                )}

            </ul>
        </nav>
    )

}

export default Pagination
