import React, {useEffect, useState} from "react"
import '../../css/Kpi.css'
import {getCookie} from "react-use-cookie"
import {Navigate} from "react-router-dom"
import {useMediaQuery} from "react-responsive"
import jwtDecode from "jwt-decode"
import {getReports} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const KPIReport = () => {
    const [nbReports, setNbReports] = useState(0)
    const [logged, setLogged] = useState(true)

    const isMobile = useMediaQuery({query: "(max-width: 450px)"})

    const [forbidden, setForbidden] = useState(false)

    useEffect(() => {
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  !hasPermissionsTo("ROLE_KPI")) {
            setForbidden(true)
        }
        else {
            fetchReports().then()

        }
    }, [])


    const fetchReports = async () => {
        const response = await getReports(1)
        if (response.status === 401) {
            setLogged(false)
        } else {
            const json = await response.json()
            setLogged(true)
            setNbReports(json["hydra:totalItems"])
        }
    }

    return (
        <div className={"container"} style={{marginTop: isMobile ? "112px" : "56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }

            {
                forbidden ? (
                    <div><p>Vous n'avez pas accès à cette page.</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div style={{width: "100%"}}>
                        <div className={"row"}>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}>Nombre de Signalements</h1>
                                <p className={"corps-vignette"}>{nbReports}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}

export default KPIReport
