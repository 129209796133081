import './css/App.css'
import Login from "./components/Login"
import Index from "./components/Index"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Bugs from "./components/Bugs"
import Reports from "./components/Reports"
import TestDrives from "./components/TestDrives"
import Users from "./components/Users"
import Navbar from "./components/Navbar"
import KPI from "./components/kpi/KPI"
import Notification from "./components/Notification"
import KPIUsers from "./components/kpi/KPIUsers"
import KPITestDrive from "./components/kpi/KPITestDrive"
import KPIReport from "./components/kpi/KPIReport"
import KPIPost from "./components/kpi/KPIPost"
import React, {useEffect, useState} from "react"
import UserInfo from "./components/infoUsers/UserInfo"
import Recherche from "./components/Recherche"
import Logout from "./components/Logout"
import Chat from "./components/Chat"
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllUsers} from "./functions/FetchFunctions"
import {setAllUsers, setLogged} from "./redux/usersSlice"
import GestionRole from "./components/gestions-role/GestionRole"
import UserContext from "./context/UserContext";

const App = () => {

    const dispatch = useDispatch()
    const logged = useSelector((state) => state.users.logged)
    const [connectedUser, setConnectedUser] = useState(null)

    useEffect(() => {
        if (logged) {
            getAllUsers().then()
        }
    }, [])

    const getAllUsers = async () => {
        const response = await fetchAllUsers()
        if (response.status === 200) {
            const json = await response.json()
            dispatch(setAllUsers(json))
            dispatch(setLogged(true))
        } else if (response.status === 401) {
            dispatch(setLogged(false))
        }
    }

    return (
        <UserContext.Provider value={{connectedUser, setConnectedUser}}>
            <div>
                <Router>
                    <header>
                        <Navbar/>
                    </header>
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/" element={<Index/>}/>
                        <Route path="/bugs" element={<Bugs/>}/>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/:userId" element={<UserInfo/>}/>
                        <Route path="/gestion" element={<GestionRole/>}/>
                        <Route path="/reports" element={<Reports/>}/>
                        <Route path="/test-drives" element={<TestDrives/>}/>
                        <Route path="/kpi" element={<KPI/>}/>
                        <Route path="/kpi/users" element={<KPIUsers/>}/>
                        <Route path="/kpi/test-drives" element={<KPITestDrive/>}/>
                        <Route path="/kpi/reports" element={<KPIReport/>}/>
                        <Route path="/kpi/posts" element={<KPIPost/>}/>
                        <Route path="/notification" element={<Notification/>}/>
                        <Route path="/recherche" element={<Recherche/>}/>
                        <Route path="/chat" element={<Chat/>}/>
                        <Route path="*" element={<Login/>}/>
                    </Routes>
                </Router>
            </div>
        </UserContext.Provider>
    );
}

export default App;
