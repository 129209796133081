import React, {useEffect, useState} from "react"
import '../../css/Kpi.css'
import {getCookie} from "react-use-cookie"
import {Navigate} from "react-router-dom"
import {useMediaQuery} from "react-responsive"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../../redux/usersSlice"
import {Spinner} from "react-bootstrap"
import jwtDecode from "jwt-decode"
import {getAllComments, getAllLikes, getAllPosts} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const KPIPost = () => {
    const [posts, setPosts] = useState(0)
    const [comments, setComments] = useState(0)
    const [bop, setBops] = useState(0)
    const [forbidden, setForbidden] = useState(false)

    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({query: "(max-width: 450px)"})

    useEffect(() => {
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles &&  !hasPermissionsTo("ROLE_KPI")) {
            setForbidden(true)
        }
    }, [])

    useEffect(() => {
        if (isMobile) {
            closeNav()
        }
        document.getElementById("posts-data").style.display = "none"
        document.getElementById("wait-fetch-posts").style.display = "block"

    }, [isMobile])

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0"
    }

    useEffect(() => {
        fetchAll().then(() => {
            if (document.getElementById("posts-data")) {
                document.getElementById("posts-data").style.display = "block"
                document.getElementById("wait-fetch-posts").style.display = "none"
            }
        })

    }, [])

    const fetchAll = async () => {
        fetchPosts().then()
        fetchComments().then()
        fetchLikes().then()
    }

    const fetchPosts = async () => {
        const response = await getAllPosts()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setPosts(json["hydra:totalItems"])
        }
    }

    const fetchComments = async () => {
        const response = await getAllComments()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setComments(json["hydra:totalItems"])
        }
    }

    const fetchLikes = async () => {
        const response = await getAllLikes()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setBops(json["hydra:totalItems"])
        }
    }

    return (
        <div className={"container"} style={{marginTop: "112px", width: "100%"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }

            {
                forbidden ? (
                    <div><p>Vous n'avez pas accès à cette page.</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div style={{width: "100%"}}>
                        <Spinner id={"wait-fetch-posts"} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <div id={"posts-data"}>
                            <div className={"row"}>
                                <div className={"col-sm vignette"}>
                                    <h1 className={"titre-vignette"}> Nombre de posts</h1>
                                    <p className={"corps-vignette"}>
                                        {posts}
                                    </p>
                                </div>
                                <div className={"col-sm vignette"}>
                                    <h1 className={"titre-vignette"}>Nombre de commentaires</h1>
                                    <p className={"corps-vignette"}>
                                        {comments}
                                    </p>
                                </div>
                                <div className={"col-sm vignette"}>
                                    <h1 className={"titre-vignette"}>Nombre de bops</h1>
                                    <p className={"corps-vignette"}>
                                        {bop}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default KPIPost
