import React, {useEffect, useState} from "react"
import '../../css/Kpi.css'
import {getCookie} from "react-use-cookie"
import Chart from 'react-apexcharts'
import {Navigate} from "react-router-dom"
import {useMediaQuery} from "react-responsive"
import {mois} from '../../global'
import jwtDecode from "jwt-decode"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../../redux/usersSlice"
import {Spinner} from "react-bootstrap"
import {
    getAllTestDrives,
    getCarsNumberPerBrand,
    getCarsNumberPerModel,
    getNbTestDrivesPerMonth
} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const KPITestDrive = () => {
    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()

    const [testDrives, setTestDrives] = useState([])
    const [cars, setCars] = useState(0)
    const [tdState, setTdState] = useState({options: {}, series: []})
    const tdMonth = []
    const tdSerie = []
    const brands = []
    const countBrand = []
    const [tdPerMonth, setTdPerMonth] = useState([])
    const [nbCarsPerBrand, setNbCarPerBrand] = useState([])
    const [nbCarPerModel, setNbCarPerModel] = useState([])
    const isMobile = useMediaQuery({query: "(max-width: 450px)"})

    const [forbidden, setForbidden] = useState(false)

    useEffect(() => {
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles && !hasPermissionsTo("ROLE_KPI")) {
            setForbidden(true)
        }
    }, [])

    useEffect(() => {
        if (document.getElementById("nb-td")) {
            document.getElementById("nb-td").style.display = "none"
            document.getElementById("td-done").style.display = "none"
            document.getElementById("cars").style.display = "none"
            document.getElementById("tdState").style.display = "none"
            document.getElementById("car-per-brand").style.display = "none"
            /*document.getElementById("car-per-model").style.display = "none"*/
        }
    }, [])


    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        matchCarBrands()
        matchTestDriveMonth()
    }, [nbCarsPerBrand, tdPerMonth])

    const fetchData = () => {

        fetchTestDrives().then(() => {
            if (document.getElementById("nb-td")) {
                document.getElementById("wait-fetch-td-nb").style.display = "none"
                document.getElementById("wait-fetch-td-done").style.display = "none"
                document.getElementById("nb-td").style.display = "block"
                document.getElementById("td-done").style.display = "block"
            }
        })
        fetchCars().then(matchCarBrands)
        fetchNbTDPerMonth().then(matchTestDriveMonth)
        fetchCarPerModel().then()
    }


    const fetchCarPerModel = async () => {
        const response = await getCarsNumberPerModel()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setNbCarPerModel(json)
        }
    }

    const fetchTestDrives = async () => {
        const response = await getAllTestDrives()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setTestDrives(json)

        }
    }

    const fetchCars = async () => {
        const response = await getCarsNumberPerBrand()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            setNbCarPerBrand(json)
            dispatch(setLogged(true))

        }
    }


    const fetchNbTDPerMonth = async () => {
        const response = await getNbTestDrivesPerMonth()
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            const json = await response.json()
            dispatch(setLogged(true))
            setTdPerMonth(json)

        }

    }


    const matchTestDriveMonth = () => {
        tdPerMonth.map(t => {
            tdMonth.push(mois[t.m - 1])
            tdSerie.push(t.count)
        })

        setTdState({
            options: {
                chart: {
                    id: 'test de diagramme'
                },
                xaxis: {
                    categories: tdMonth
                }
            },
            series: [{
                name: 'Nombre de test-drives par mois',
                data: tdSerie
            }]
        })

        if (tdPerMonth.length > 0 && document.getElementById("wait-fetch-td-per-month")) {
            document.getElementById("wait-fetch-td-per-month").style.display = "none"
            document.getElementById("wait-fetch-cars-nb").style.display = "none"
            document.getElementById("tdState").style.display = "block"
            document.getElementById("car-per-brand").style.display = "block"

        }

    }

    const matchCarBrands = () => {
        let count = 0

        nbCarsPerBrand.map(t => {
            brands.push(t.name)
            countBrand.push(t.count)
            count = count + t.count
        })

        setCars(count)

        if (nbCarsPerBrand.length > 0 && document.getElementById("car-per-brand")) {
            document.getElementById("wait-fetch-car-per-brand").style.display = "none"
            document.getElementById("wait-fetch-cars-nb").style.display = "none"
            document.getElementById("cars").style.display = "block"
            document.getElementById("car-per-brand").style.display = "block"

        }

    }

    return (
        <div className={"container"} style={{marginTop: isMobile ? "112px" : "56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }
            {
                forbidden ? (
                    <div><p>Vous n'avez pas accès à cette page.</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div style={{width: "100%"}}>

                        <div className={"row"}>
                            <div className={"col-sm col-donnees"}>
                                <div className={"col-sm vignette w-100"}>
                                    <h1 className={"titre-vignette"}> Nombre de test-drives demandés</h1>
                                    <Spinner id={"wait-fetch-td-nb"} animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <p id="nb-td" className={"corps-vignette"}>
                                        {testDrives.length}
                                    </p>
                                </div>
                                <div className={"col-sm vignette w-100"}>
                                    <h1 className={"titre-vignette"}> Nombre de test drive effectué </h1>
                                    <Spinner id={"wait-fetch-td-done"} animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <p id={"td-done"} className={"corps-vignette"}>
                                        {testDrives.filter(td => td.isValidated === 2).length}
                                    </p>
                                </div>
                                <div className={"col-sm vignette w-100"}>
                                    <h1 className={"titre-vignette"}> Nombre de voitures</h1>
                                    <Spinner id={"wait-fetch-cars-nb"} animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <p id={"cars"} className={"corps-vignette"}>
                                        {cars}
                                    </p>
                                </div>
                            </div>

                            <div className={"col-sm vignette w-100"}>
                                <h1 className={"titre-vignette"}> Nombre de test-drives par mois</h1>
                                <Spinner id={"wait-fetch-td-per-month"} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                <Chart id={"tdState"} options={tdState.options} series={tdState.series} type="bar"
                                       width={"100%"} height={320}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}> Nombre de véhicules par marques</h1>
                                <Spinner id={"wait-fetch-car-per-brand"} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>

                                <div id={"car-per-brand"} className="cadre-table-scroll w-100">
                                    <table className="table-scroll w-100">
                                        <thead>
                                        <tr>
                                            <th>Marques</th>
                                            <th>Nombre</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {nbCarsPerBrand && nbCarsPerBrand.map((t, index) => (
                                            <tr key={index}>
                                                <td>{t.name}</td>
                                                <td>{t.count}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}> Nombre de véhicules par modèles</h1>
                                {/*<Spinner id={"wait-fetch-car-per-model"} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>*/}
                                <div id={"car-per-model"} className="cadre-table-scroll w-100">
                                    <table className="table-scroll w-100">
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Modèle</th>
                                            <th>Marque</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {nbCarPerModel && nbCarPerModel.map((c, index) => (
                                            <tr key={index}>
                                                <td>{c.count}</td>
                                                <td>{c.model}</td>
                                                <td>{c.brand}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {nbCarPerModel.length === 0 && (
                                        <p className="text-muted">Attente des données de l'API</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )

}

export default KPITestDrive
