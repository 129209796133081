import React, {useEffect, useState} from "react"
import {HashLink as Link} from "react-router-hash-link"
import noPic from "../../assets/nopic.jpg"
import '../../css/admin.scss'
import '../../css/index.css'
import '../../css/UserInfo.css'
import '../../css/MenuKpi.css'

const Garage = (props) => {

    const [carState, setCarState] = useState({
        showingCarModal: false,
        carShown: {}
    })

    useEffect(() => {
        const close = (e) => {
            if(e.key === "Escape"){
                setCarState({
                    showingCarModal: false,
                    carShown: {}
                })
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    },[])

    const CarModal = () => (
        <div className={" bg-modal "}>
            <div className="card mb-3 car-modal">

                <div className="row g-0" style={{height: "80%"}}>
                    <div className="col-lg-4">
                        <div className="user-avatar w-100 h-100"
                             style={{
                                 backgroundImage: carState.carShown.carPicture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + carState.carShown.carPicture + "')" : "url(" + noPic + ")",

                             }}>
                        </div>
                    </div>
                    <div className="col">

                        <div className="card-body">
                            <h5 className="card-title fw-bold">{carState.carShown.title}</h5>
                            <p className="card-text">
                                Model : {carState.carShown.carModel && carState.carShown.carModel.name}
                                <br/>
                                Marque : {carState.carShown.carModel.brand && carState.carShown.carModel.brand.name}
                                <br/>
                                Année : {carState.carShown.year}
                                <br/>
                                Génération : {carState.carShown.carGeneration && carState.carShown.carGeneration.name}
                                <br/>
                                Série : {carState.carShown.carSerie && carState.carShown.carSerie.name}
                                <br/>
                            </p>
                        </div>
                    </div>
                    <div className="col col-border">
                        <div className="card-body">
                            <p className="card-text">
                                Moteur : {carState.carShown.carTrim && carState.carShown.carTrim.name}
                                <br/>
                                Couleur extérieure : {carState.carShown.outColor && carState.carShown.outColor}
                                <br/>
                                Couleur intérieure : {carState.carShown.inColor && carState.carShown.inColor}
                                <br/>
                                Nombre de chevaux : {carState.carShown.dinPower && carState.carShown.dinPower}
                                <br/>
                                Nombre de places : {carState.carShown.placesNb && carState.carShown.placesNb}
                                <br/>
                                Ville : {carState.carShown.city && carState.carShown.city}
                                <br/>
                                Nombre de portières : {carState.carShown.doorNb && carState.carShown.doorNb}

                            </p>
                        </div>
                    </div>
                    <div className="col-sm ">
                        <a href="#" className={"closebtn"} onClick={(event) => {
                            event.preventDefault()
                            setCarState({showingCarModal: false, carShown: {}})
                        }}>&times;</a>
                    </div>
                </div>
            </div>
        </div>
    )


    return (
        <div id={"garage"} className="card border-0 p-1 mb-3">

            {
                carState.showingCarModal && <CarModal />
            }

            <div className="card-body">
                <div className="row">
                    <h3>GARAGE {props.user.cars && `: ${props.user.cars.length}`}</h3>
                    {props.user.cars && props.user.cars.length === 0 ? (
                            <p className="text-muted">Cet utilisateur n'a aucune voiture
                                enregistrée.</p>
                        )
                        : props.user.cars && props.user.cars.map((car, index) => (
                        <div key={index} className="col-md-6">
                            <Link style={{textDecoration: "none", color: "black"}} to="#"
                                  onClick={(event) => {
                                      event.preventDefault()
                                      setCarState({showingCarModal: true, carShown: car})
                                  }}>


                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-lg-4">
                                            <div className="user-avatar w-100 h-100"
                                                 style={{
                                                     backgroundImage: car.carPicture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + car.carPicture + "')" : "url('img/nopic.jpg')",
                                                 }}>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <h5 className="card-title fw-bold">{car.title}</h5>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    Model : {car.carModel && car.carModel.name}
                                                    <br/>
                                                    Marque : {car.carModel.brand && car.carModel.brand.name}
                                                    <br/>
                                                    Année : {car.year}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Garage
