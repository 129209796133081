import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import '../../css/GestionRole.css'
import {Navigate} from "react-router-dom"
import {useMediaQuery} from "react-responsive"
import UserCard from "../UserCard"
import {updateRoles} from "../../functions/FetchFunctions";
import {hasPermissionsTo} from "../../functions/rolesFunctions";
import {getCookie} from "react-use-cookie";
import GestionContext from "../../context/GestionContext";

const Gestion = () => {
    const isMobile = useMediaQuery({query: "(max-width:450px)"})

    const adminList = useSelector((state) => state.users.value).filter(u => u.roles.includes("ROLE_ADMIN"))

    const [form, setForm] = useState({recherche: ""})
    const [forbidden, setForbidden] = useState(false)
    const [currentUser, setCurrentUser] = useState(null)
    const [userChosen, setUserChosen] = useState({
        user: null,
        roles: [],
        chatChecked: false,
        notificationChecked: false,
        kpiChecked: false,
        moderationChecked: false
    })

    useEffect(() => {
        console.log('current', currentUser)
    }, [currentUser])

    const attributable_roles = [
        {
            name: "ROLE_CHAT",
            description: "Permet à l'utilisateur d'accéder à la page de chat et d'envoyer des messages privés"
        },
        {
            name: "ROLE_NOTIFICATION",
            description: "Permet à l'utilisateur d'accéder à la page de notification et d'envoyer une notification globale à tous les utilisateurs"
        },
        {
            name: "ROLE_KPI",
            description: "Permet à l'utilisateur d'accéder à la page KPI et de voir toutes les données qu'elle présente"
        },
        {name: "ROLE_MODERATION", description: "Permet à l'utilisateur de supprimer des posts et des commentaires"},
    ]

    const [reponseFetch, setReponseFetch] = useState({
        showSuccess: false,
        showError: false
    })

    useEffect(() => {
        if (document.cookie.length !== 0 && !hasPermissionsTo("ROLE_SUPER_ADMIN")) {
            setForbidden(true)
        }
    }, [])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleCheck = (roles) => {
        console.log(roles)
        let roleAdded = [...userChosen.roles], roleRemoved = userChosen.roles.filter(r => r !== roles)
        roleAdded.push(roles)

        if (userChosen.roles.includes(roles)) {
            setUserChosen((prevState) => ({
                ...prevState,
                roles: roleRemoved,
                chatChecked: roleRemoved.includes("ROLE_CHAT"),
                notificationChecked: roleRemoved.includes("ROLE_NOTIFICATION"),
                kpiChecked: roleRemoved.includes("ROLE_KPI"),
                moderationChecked: roleRemoved.includes("ROLE_MODERATION")
            }))

        } else {
            setUserChosen((prevState) => ({
                ...prevState,
                roles: roleAdded,
                chatChecked: roleAdded.includes("ROLE_CHAT"),
                notificationChecked: roleAdded.includes("ROLE_NOTIFICATION"),
                kpiChecked: roleAdded.includes("ROLE_KPI"),
                moderationChecked: roleAdded.includes("ROLE_MODERATION")

            }))
        }
    }

    const saveRoles = async (user) => {
        const response = await updateRoles(user.id, userChosen.roles)

        if (response.status === 200) {
            setReponseFetch({
                showSuccess: true,
                showError: false
            })
        } else {
            setReponseFetch({
                showSuccess: false,
                showError: true
            })
        }

        setTimeout(() => {
            setReponseFetch({
                showSuccess: false,
                showError: false
            })
            window.location.reload(false)
        }, 4000)
    }

    const handleDesc = (int) => {
        document.getElementById("role-desc").innerText = attributable_roles[int].description
    }

    const SuccessModal = () => (
        <div className={" bg-modal p-3"}>

            {
                reponseFetch.showSuccess ? (
                    <div className={"card mb-3 alert-modal p-3 success"}>
                        <h5>Succès</h5>
                        <p>L'utilisateur doit se reconnecter pour que les permissions prennent effet</p>
                    </div>

                ) : (
                    <div className={"card mb-3 alert-modal p-3 failure"}>
                        <h5>Échec</h5>
                        <p>Une erreur est survenue</p>
                    </div>
                )
            }

        </div>
    )

    const CheckBoxRole = (props) => (
        <label className={"role-label"}>
            <input
                type={"checkbox"}
                checked={props.checked}
                onChange={() => handleCheck(props.roleName)}/>
            <h6 onClick={() => handleDesc(props.roleNumber)}>{props.roleName.replace('_', ' ')}</h6>

        </label>
    )

    const deleteFromAdmin = async (user) => {
        return fetch(`${process.env.REACT_APP_API_ADDRESS}users/${user.id}/roles`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie('token')}`
            },
            body: JSON.stringify({
                roles: ['ROLE_USER']
            })
        })
    }

    return (
        <GestionContext.Provider value={{currentUser}}>
            <div className={"container chat-section"} style={{paddingTop: !isMobile && "60px", overflow: "hidden"}}>

                {
                    forbidden ? (
                        <div className={"container"}>
                            <p>Vous n'avez pas accès à cette page</p>
                            <Navigate to={"/"}/>
                        </div>
                    ) : (

                        <div className={"gestion-role-div"}>
                            <div className={"admin-liste"}>

                                <div className="input-group">
                                    <div className="form-outline">

                                        <input type="search"
                                               id="form1"
                                               name="recherche"
                                               required="required"
                                               className="form-control"
                                               value={form.recherche}
                                               placeholder="Rechercher un administrateur"
                                               onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className={"all-admin"}>
                                    {adminList && adminList.filter(u => u.pseudo.toLowerCase().includes(form.recherche.toLowerCase())).map((user, index) => (
                                        <div key={index} className="col-md-6" style={{width: "100%"}} role='button'
                                             onClick={() => {
                                                 setCurrentUser(user)
                                                 setUserChosen({
                                                     user: user,
                                                     roles: user.roles,
                                                     chatChecked: user.roles.includes("ROLE_CHAT"),
                                                     notificationChecked: user.roles.includes("ROLE_NOTIFICATION"),
                                                     kpiChecked: user.roles.includes("ROLE_KPI"),
                                                     moderationChecked: user.roles.includes("ROLE_MODERATION")
                                                 })
                                             }}>


                                            <UserCard user={user}/>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={"user-chosen p-5 col-border"}>
                                {userChosen.user && (
                                    <div style={{height: "100%"}}>
                                        <h4>Gestion des rôles de {userChosen.user.pseudo}</h4>

                                        <div className={"role-choosing-box"}>

                                            <CheckBoxRole roleName={"ROLE_CHAT"} roleNumber={0}
                                                          checked={userChosen.chatChecked}/>
                                            <CheckBoxRole roleName={"ROLE_NOTIFICATION"} roleNumber={1}
                                                          checked={userChosen.notificationChecked}/>
                                            <CheckBoxRole roleName={"ROLE_KPI"} roleNumber={2}
                                                          checked={userChosen.kpiChecked}/>
                                            <CheckBoxRole roleName={"ROLE_MODERATION"} roleNumber={3}
                                                          checked={userChosen.moderationChecked}/>

                                            <div className={"container role-description"}>
                                                <h6>Description du rôle</h6>
                                                <p id={"role-desc"}></p>
                                            </div>

                                        </div>

                                        <div className={'d-flex'}>
                                            <button className={"btn btn-success"}
                                                    onClick={async () => {
                                                        await saveRoles(userChosen.user)
                                                    }}>Valider
                                            </button>

                                            <button className={'btn btn-danger ms-3'}
                                                    onClick={async () => {
                                                        if (window.confirm('Enlever les droits de cet utilisateur ?')) {
                                                            const result = await deleteFromAdmin(userChosen.user);
                                                            if (result.status === 200) {
                                                                window.location.reload(false)
                                                            }
                                                        }
                                                    }
                                                    }>
                                                Supprimer des administrateurs
                                            </button>
                                        </div>

                                        {
                                            (reponseFetch.showError || reponseFetch.showSuccess) && <SuccessModal/>
                                        }

                                    </div>

                                )}
                            </div>
                        </div>
                    )
                }

            </div>
        </GestionContext.Provider>
    )
}
export default Gestion
