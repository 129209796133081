import React, {useContext, useState} from "react";
import '../css/Login.scss';
import logo from '../assets/bopper-min.png'
import {Navigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie/lib";
import {useDispatch, useSelector} from "react-redux";
import {setLogged} from "../redux/usersSlice";
import {userLogin} from "../functions/FetchFunctions";
import UserContext from "../context/UserContext";

function Login() {

    const [form, setForm] = useState({email: '', password: ''})
    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()
    const cookies = new Cookies()
    const {setConnectedUser} = useContext(UserContext)

    const handleChange = (event) => {
        event.preventDefault()
        const name = event.target.name
        const value = event.target.value

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const response = await userLogin(form)
        const data = await response.json()
        if (response.status === 200) {
            const user = jwtDecode(data["token"])
            setConnectedUser(user)
            console.log('connected', user)
            if (checkConnectionRoles(user.roles)) {
                cookies.set(
                    'token', data["token"], {
                        secure: true, sameSite: 'none'
                    }
                );
                cookies.set(
                    'mercureToken', data["mercure_token"], {
                        secure: true, sameSite: 'none'
                    }
                );
                dispatch(setLogged(true))
            }
        }
    }

    const checkConnectionRoles = (roles) => {
        // ONLY ADMIN AND SUPER_ADMIN CAN CONNECT
        const rolesAllowed = new Set(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])
        const rolesSet = new Set(roles);

        const rolesFound = [...rolesSet].filter(
            el => rolesAllowed.has(el)
        );

        console.log(rolesFound)
        return rolesFound.length > 0;
    }

    return (
        <div className="login-content rounded bg-white m-auto notification">
            {
                logged && (
                    <Navigate to="/"/>
                )
            }
            <form onSubmit={handleSubmit} method="post" style={{paddingTop: "56px"}}>
                <div className="text-center">
                    <img src={logo} alt="Logo" height="35"/>
                </div>
                <label htmlFor="inputEmail">Identifiant</label>
                <input onChange={handleChange}
                       type="email" value={form.email} name="email" id="inputEmail" className="form-control"
                       autoComplete="email" required autoFocus/>
                <label className="mt-3" htmlFor="inputPassword">Mot de passe</label>
                <input onChange={handleChange} type="password" value={form.password} name="password" id="inputPassword"
                       className="form-control"
                       autoComplete="current-password"
                       required/>
                <div className="d-flex w-100">
                    <button className="ms-auto mt-3 btn btn-bopper" type="submit">
                        Connexion
                    </button>
                </div>
            </form>
        </div>
    );

}

export default Login;
