const AuvergneRhoneAlpes = ["Ain", "Allier", "Ardèche", "Cantal", "Drôme", "Isère", "Loire", "Haute-Loire", "Puy-de-Dôme", "Rhône", "Savoie", "Haute-Savoie"]
const BourgogneFrancheComte = ["Côte-d'Or", "Doubs", 'Jura', 'Nièvre', 'Haute-Saône', 'Saône-et-Loire', "Yonne", "Territoire-de-Belfort"]
const bretagne = ["Côtes d'Armor", "Finistère", "Ille-et-Vilaine", "Morbihan"]
const CentreValDeLoire = ["Cher", "Eure-et-Loir", "Indre", "Indre-et-Loire", "Loir-et-cher", "Loiret"]
const corse = ["Corse-du-Sud", "Haute-Corse"]
const GrandEst = ["Ardennes", "Aube", "Marne", "Haute-Marne", "Meurthe-et-Moselle", "Meuse", "Moselle", "Bas-Rhin", "Haut-Rhin", "Vosges"]
const hautDeFrance = ["Nord", "Pas-de-Calais", "Somme", "Oise", "Aisne"]
const IleDeFrance = ["Paris", "Seine-et-Marne", "Yvelines", "Essonne", "Hauts-de-Seine", "Seine-Saint-Denis", "Val-de-Marne", "Val-d'Oise"]
const Normandie = ["Calvados", "Eure", "Manche", "Orne", "Seine-Maritime"]
const NouvelleAquitaine = ["Charente", "Charente-Maritime", "Corrèze", "Creuse", "Dordogne", "Gironde", "Landes", "Lot-et-Garonne", "Pyrénées-Atlantiques", "Deux-Sèvres", "Vienne", "Haute-Vienne"]
const Occitanie = ["Ariège", "Aude", "Aveyron", "Gard", "Haute-Garonne", "Gers", "Hérault", "Lot", "Lozère", "Hautes-Pyrénées", "Pyrénées-Orientales", "Tarn", "Tarn-et-Garonne"]
const paysDeLaLoire = ["Loire-Atlantique", "Maine-et-Loire", "Mayenne", "Sarthe", "Vendée"]
const provenceAlpesCoteDAzur = ["Alpes-de-Haute-Provence", "Hautes-Alpes", "Alpes-Maritimes", "Bouches-du-Rhône", "Var", "Vaucluse"]

export const regions = [{nom: "Auvergne-Rhône-Alpes", liste: AuvergneRhoneAlpes},
    {nom: "Bourgogne-Franche-Comté", liste: BourgogneFrancheComte}, {
        nom: "Bretagne",
        liste: bretagne
    }, {nom: "Centre-Val de Loire", liste: CentreValDeLoire},
    {nom: "Corse", liste: corse}, {nom: "Grand Est", liste: GrandEst}, {
        nom: "Hauts-de-France",
        liste: hautDeFrance
    }, {nom: "Île-de-France", liste: IleDeFrance}, {nom: "Normandie", liste: Normandie},
    {nom: "Nouvelle-Aquitaine", liste: NouvelleAquitaine}, {
        nom: "Occitanie",
        liste: Occitanie
    }, {nom: "Pays de la Loire", liste: paysDeLaLoire}, {
        nom: "Provence-Alpes-Côte d'Azur",
        liste: provenceAlpesCoteDAzur
    }]

export const departements = ["Département",
    "Ain",
    "Aisne",
    "Allier",
    "Alpes-de-Haute-Provence",
    "Hautes-Alpes",
    "Alpes-Maritimes",
    "Ardèche",
    "Ardennes",
    "Ariège",
    "Aube",
    "Aude",
    "Aveyron",
    "Bouches-du-Rhône",
    "Calvados",
    "Cantal",
    "Charente",
    "Charente-Maritime",
    "Cher",
    "Corrèze",
    "Haute-Corse",
    "Côte-d'Or",
    "Côtes d'Armor",
    "Creuse",
    "Dordogne",
    "Doubs",
    "Drôme",
    "Eure",
    "Eure-et-Loir",
    "Finistère",
    "Gard",
    "Haute-Garonne",
    "Gers",
    "Gironde",
    "Hérault",
    "Ille-et-Vilaine",
    "Indre",
    "Indre-et-Loire",
    "Isère",
    "Jura",
    "Landes",
    "Loir-et-Cher",
    "Loire",
    "Haute-Loire",
    "Loire-Atlantique",
    "Loiret",
    "Lot",
    "Lot-et-Garonne",
    "Lozère",
    "Maine-et-Loire",
    "Manche",
    "Marne",
    "Haute-Marne",
    "Mayenne",
    "Meurthe-et-Moselle",
    "Meuse",
    "Morbihan",
    "Moselle",
    "Nièvre",
    "Nord",
    "Oise",
    "Orne",
    "Pas-de-Calais",
    "Puy-de-Dôme",
    "Pyrénées-Atlantiques",
    "Hautes-Pyrénées",
    "Pyrénées-Orientales",
    "Bas-Rhin",
    "Haut-Rhin",
    "Rhône",
    "Haute-Saône",
    "Saône-et-Loire",
    "Sarthe",
    "Savoie",
    "Haute-Savoie",
    "Paris",
    "Seine-Maritime",
    "Seine-et-Marne",
    "Yvelines",
    "Deux-Sèvres",
    "Somme",
    "Tarn",
    "Tarn-et-Garonne",
    "Var",
    "Vaucluse",
    "Vendée",
    "Vienne",
    "Haute-Vienne",
    "Vosges",
    "Yonne",
    "Territoire-de-Belfort",
    "Essonne",
    "Hauts-de-Seine",
    "Seine-Saint-Denis",
    "Val-de-Marne",
    "Val-D'Oise",
    "Guadeloupe",
    "Martinique",
    "Guyane",
    "La Réunion",
    "Mayotte",
]

export const userRegion = [
    "Auvergne-Rhône-Alpes", "Bourgogne-Franche-Comté", "Bretagne", "Centre-Val de Loire","Corse",
    "Grand Est", "Hauts-de-France","Île-de-France","Normandie","Nouvelle-Aquitaine","Occitanie",
    "Pays de la Loire", "Provence-Alpes-Côte d'Azur"
]