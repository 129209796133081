import {useLayoutEffect, useState} from "react";
import "../css/Notification.css"

const ModalConfirmNotif = ({isVisible, setVisible, titre, contenu, sendNotification}) => {

    const [secretCode, setSecretCode] = useState('')
    const [errorSecretCode, setErrorSecretCode] = useState(false)

    useLayoutEffect(() => {
        if (isVisible) {
            const focusableElements =
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            const modal = document.getElementById('modal'); // select the modal by it's id

            const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
            const focusableContent = modal.querySelectorAll(focusableElements);
            const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

            document.addEventListener('keydown', function (e) {
                if (e.key === 'Escape') {
                    setVisible(false)
                }
            })

            document.addEventListener('keydown', function (e) {
                let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) { // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else { // if tab key is pressed
                    if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                        firstFocusableElement.focus(); // add focus for the first focusable element
                        e.preventDefault();
                    }
                }
            });

            firstFocusableElement.focus();
        }
    }, [isVisible])

    if (!isVisible) return <></>

    return (
        <div
            id={'modal'}
            style={{
                backgroundColor: '#000a',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                display: 'flex',
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    width: '50%',
                    height: 'auto',
                    padding: 10,
                    borderRadius: 5
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderWidth: '0 0 1px 0',
                        borderStyle: 'solid'
                    }}
                >
                    <h1>
                        Confirmer l'envoi de la notification ?
                    </h1>
                    <button
                        onClick={() => setVisible(false)}
                        className={"modal_close_button"}
                    >
                        X
                    </button>
                </div>

                <div
                    style={{
                        paddingTop: 20
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <h2 style={{width: '20%'}}>Titre :</h2>
                        <h2
                            style={{
                                paddingLeft: 10
                            }}
                        >
                            {titre}
                        </h2>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <h2 style={{width: '20%'}}>Contenu :</h2>
                        <h2
                            style={{
                                paddingLeft: 10
                            }}
                        >
                            {contenu}
                        </h2>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 30,
                        alignItems: 'center'
                    }}
                >
                    <div
                        className={'div_input_modal_secret_code'}
                    >
                        <span style={{fontWeight: '700'}}>
                            Code secret :
                        </span>
                        <input
                            placeholder={'Code secret obligatoire'}
                            value={secretCode}
                            onChange={(event) => {
                                if (errorSecretCode) setErrorSecretCode(false)
                                setSecretCode(event.target.value)
                            }}
                            type={'number'}
                            className={'input_modal_secret_code'}
                        />
                    </div>
                    {
                        errorSecretCode &&
                        <span
                            style={{
                                color: 'white',
                                fontWeight: '700',
                                backgroundColor: '#f009',
                                borderRadius: 5,
                                padding: 5
                            }}
                        >Le code secret est incorrect !</span>
                    }

                    <button
                        onClick={() => {
                            if (process.env.REACT_APP_SECRET_CODE !== secretCode) {
                                setErrorSecretCode(true);
                            } else {
                                setVisible(false);
                                sendNotification();
                            }
                        }}
                        className={"confirm_modal_secret_code"}
                    >
                        Confirmer
                    </button>
                </div>

            </div>
        </div>
    )
}

export default ModalConfirmNotif
