import Bugs from "./Bugs"
import Reports from "./Reports"
import Users from "./Users"
import TestDrives from "./TestDrives"
import React, {useEffect} from "react"
import {getCookie} from "react-use-cookie"
import {Navigate} from "react-router-dom"
import moment from "moment"
import jwtDecode from "jwt-decode"
import {useDispatch, useSelector} from "react-redux"
import {setLogged} from "../redux/usersSlice"

const Index = () => {

    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()

    useEffect(()=>{
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles) {
            //vérifier si le token dans le cookies est expiré
            let date = new Date("01/01/1970")
            let seconds = jwtDecode(getCookie('token')).exp
            date.setSeconds(date.getSeconds() + seconds)
            if (moment().isAfter(date)) {
                dispatch(setLogged(false))
            }
        }
    },[])

    return (
        <div className={"container"} style={{marginTop:"56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }
            <Bugs limite={4}/>

            <Reports limite={4}/>

            <Users limite={4}/>

            <TestDrives limite={4}/>

        </div>
    )

}

export default Index