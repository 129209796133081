import React, {useEffect, useState} from "react"
import noPic from '../assets/nopic.jpg'
import '../css/Chat.css'
import {getCookie} from "react-use-cookie"
import {Link, Navigate, useSearchParams} from "react-router-dom"
import jwtDecode from "jwt-decode"
import moment from "moment"
import {useMediaQuery} from "react-responsive"
import {useSelector} from "react-redux"
import {Spinner} from "react-bootstrap"
import {
    createDiscussion,
    createDiscussionMessage,
    getDiscussionSeensStatus,
    getLastDiscussions,
    seeDiscussion
} from "../functions/FetchFunctions";
import {hasPermissionsTo} from "../functions/rolesFunctions";
import {fetchEventSource} from "@microsoft/fetch-event-source";

const Chat = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [form, setForm] = useState({writeBar: '', recherche: ""})
    const [chat, setChat] = useState([])
    const isMobile = useMediaQuery({query: "(max-width: 430px)"})
    const [noDiscussion, setNoDiscussion] = useState(false)
    const [discussionNotSeen, setDiscussionNotSeen] = useState([])
    const [forbidden, setForbidden] = useState(false)
    const [pageState, setPageState] = useState({
        page: 1,
        nextPage: 1,
        pages: []
    })
    const [discussionState, setDiscussionState] = useState({
        discussions: [],
        convId: 1,
        lastPage: 1,
        userConv: {}
    })
    const [users, setUsers] = useState([])
    const [logged, setLogged] = useState(true)

    const usersFetch = useSelector((state) => state.users.value)
    const mercureToken = getCookie('mercureToken')

    useEffect(() => {
        fetchConv().then()
    }, [pageState])

    useEffect(() => {
        if (document.cookie.length !== 0) {
            if (!hasPermissionsTo("ROLE_CHAT")) {
                setForbidden(true)
            } else {
                document.getElementById("wait-fetch").style.display = "none"
            }
        }
    }, [])

    useEffect(() => {
        if (discussionState.discussions.length > 0 && discussionState.discussions.filter(d => d.id === discussionState.convId).length > 0) {
            setChat(discussionState.discussions.filter(d => d.id === discussionState.convId)[0].events)
            setNoDiscussion(false)
        } else {
            setNoDiscussion(true)
        }
    }, [discussionState])


    useEffect(() => {
        if (searchParams.get("pseudo") && usersFetch.length > 0) {
            document.getElementById("conversation-div").style.display = "none"
            document.getElementById("wait-fetch").style.display = "block"
            createConv(usersFetch.filter(u => u.pseudo === searchParams.get("pseudo"))[0].id).then(() => {
                document.getElementById("conversation-div").style.display = "block"
                document.getElementById("wait-fetch").style.display = "none"
            })
        }
    }, [usersFetch])

    const changePage = (event, id) => {
        event.preventDefault()
        setPageState({
            page: id,
            nextPage: id < discussionState.lastPage && id + 1,
            pages: Array.from({length: discussionState.lastPage}, (_, i) => i + 1)
        })
    }

    const createConv = async (id) => {
        const response = await createDiscussion(id)
        if (response.status === 401) {
            setLogged(false)
        } else if (response.status === 201) {
            const json = await response.json()

            await fetchConv()
            await loadConv(json.id, id)
        } else if (response.status === 409) {
            const json = await response.json()

            for (let i = 1; i <= discussionState.lastPage && discussionState.discussions.filter(d => d.id == json.id).length === 0; i++) {
                setPageState((prevState) => ({
                    ...prevState,
                    page: i,
                    nextPage: i + 1
                }))
                await fetchConv()

            }
            await loadConv(json.id, id)
        }
    }

    const sendMessage = async (id) => {
        if (form.writeBar !== '') {
            const response = await createDiscussionMessage(form.writeBar, id)
            if (response.status === 401) {
                setLogged(false)
            } else {
                setForm({writeBar: '', recherche: ''})
                fetchConv().then(() => {
                    loadConv(id, discussionState.userConv.id)
                    setPageState({
                        page: 1,
                        nextPage: 2 <= discussionState.lastPage && 2,
                        pages: Array.from({length: discussionState.lastPage}, (_, i) => i + 1)
                    })
                    addClass(0)
                })
            }
        }
    }

    const seeHistoryConv = (event) => {
        event.preventDefault()
        if (isMobile) {
            document.getElementById("history-conv").classList.add("showing")
            document.getElementById("history-conv").classList.remove("notShowing")
            document.getElementById("form1").style.display = "block"
        }
    }

    const handleResearch = (event) => {
        const value = event.target.value

        setForm({writeBar: '', recherche: value})

        setUsers(usersFetch)
        if (value.length === 0) {
            setUsers([])
        }
    }

    const addClass = (index) => {
        const divsLength = document.getElementsByClassName("apercu-conv")
        for (let i = 0; i < divsLength.length; i++) {
            document.getElementsByClassName("apercu-conv")[i].classList.remove("isOpen")
            if (document.getElementsByClassName("apercu-conv")[i].id === "" + index) {
                document.getElementsByClassName("apercu-conv")[i].classList.add("isOpen")
            }
        }
    }

    const handleChange = (event) => {
        const value = event.target.value
        setForm({writeBar: value, recherche: ""})
        setUsers([])
    }

    const loadConv = async (id, userId) => {
        let tmp = discussionState.discussions.filter(d => d.id === id)
        if (tmp.length > 0) {
            await seeDiscussion(
                tmp[0].discussionSeens.filter(d => d.participant.user.id === jwtDecode(getCookie('token')).id)[0].id
            )

            setChat(tmp[0].events)
            setDiscussionState((prevState) => ({
                ...prevState,
                convId: id,
                userConv: usersFetch.filter(u => u.id === userId)[0]
            }))

            if (isMobile) {
                document.getElementById("history-conv").classList.add("notShowing")
                document.getElementById("history-conv").classList.remove("showing")
                document.getElementById("form1").style.display = "none"
            }
            document.getElementsByClassName("message-box")[0].scrollTo(0, 1000000)
        }
    }

    const fetchConv = async (reload) => {
        const response = await getLastDiscussions(pageState.page)

        if (response.status === 200) {
            setLogged(true)
            const json = await response.json()

            if (!reload) {
                setDiscussionState({
                    convId: json["hydra:member"].length > 0 && json["hydra:member"][0].id,
                    discussions: json["hydra:member"],
                    userConv: json["hydra:member"][0].participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user,
                    lastPage: json["hydra:view"] && json["hydra:view"]["hydra:last"].split("=")[1]
                })
            } else {
                setDiscussionState((prevState) => ({
                    ...prevState,
                    discussions: json['hydra:member'],
                    lastPage: json["hydra:view"] && json["hydra:view"]["hydra:last"].split("=")[1]
                }))
            }

        } else if (response.status === 401) {
            setLogged(false)
        }

        const res = await getDiscussionSeensStatus()

        if (res.status === 200) {
            const json = await res.json()
            setDiscussionNotSeen(json["hydra:member"])
            document.getElementById("notif-circle").style.display = json["hydra:totalItems"] > 0 ? "block" : "none"
            document.getElementById("notif-circle2").style.display = json["hydra:totalItems"] > 0 ? "block" : "none"
        }

        const messageBox = document.getElementById('message-box');
        messageBox.scrollTo({ left: 0, top: messageBox.scrollHeight, behavior: "smooth" });
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchEventSource('https://hub.bopper.fr/.well-known/mercure?topic=https://api.bopper.fr/api/messages/{id}', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + mercureToken
                },
                onmessage(message) {
                    console.log('MESSAGE', message)
                    fetchConv(true)
                },
                onerror(err) {
                    console.log('ERREUR', err)
                },
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        console.log("Connection made ", res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.log("Client side error ", res);
                    }
                }
            })
        }

        fetchData();
    }, [])

    useEffect(() => {
        const messageBox = document.getElementById('message-box');
        messageBox.scrollTo({ left: 0, top: messageBox.scrollHeight, behavior: "smooth" });
    }, [discussionState])

    return (
        <div className={"chat-section"}>
            {!logged && (<Navigate to={"/login"}/>)}
            {
                forbidden ? (
                    <div className={"container"} style={{marginTop: "60px"}}>
                        <p>Vous n'avez pas accès à cette page</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div style={{width: "100%"}} className={"chat-section"}>
                        <div id="history-conv"
                             className={isMobile ? "history-conv showing" : "history-conv not-mobile-history-conv-setting"}>


                            <div className="input-group">
                                <div className="form-outline form-chat">

                                    <input type="search"
                                           id="form1"
                                           name="recherche"
                                           required="required"
                                           className="form-control"
                                           style={{ marginTop: 10 }}
                                           value={form.recherche}
                                           placeholder="Rechercher un utilisateur"
                                           onChange={handleResearch}
                                    />
                                </div>
                            </div>

                            <div className={"all-conv"}>


                                {!noDiscussion ? discussionState.discussions && discussionState.discussions.map((d, index) => d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id).length > 0 ? (

                                    <div
                                        className={"apercu-conv"}
                                        key={index}
                                        id={"" + index}
                                        onClick={async () => {
                                            const res = await getDiscussionSeensStatus()

                                            if (res.status === 200) {
                                                const json = await res.json()
                                                setDiscussionNotSeen(json["hydra:member"])
                                            }
                                            loadConv(d.id, d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.id)
                                            addClass(index)
                                        }}
                                    >
                                        <Link style={{width: "15%"}}
                                              to={"/" + d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.id}>
                                            <img className={"user-pp"}
                                                 src={d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.picture ? process.env.REACT_APP_ADDRESS + "img/avatar/" + d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.picture : noPic}
                                                 alt={"Photo"}/>
                                        </Link>

                                        <span onClick={() => {
                                            loadConv(d.id, d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.id)
                                            addClass(index)
                                        }} style={{
                                            cursor: "pointer",
                                            fontWeight: discussionNotSeen.filter(discu => discu.discussion === d.id)[0] ? "bold" : "normal"
                                        }}
                                              className={"user-pseudo"}>{d.participants.filter(p => p.user.id !== jwtDecode(getCookie('token')).id)[0].user.pseudo}</span>
                                        <small className={"conv-date"}>{moment(d.lastUpdate).format("DD/MM/YY")}</small>
                                    </div>
                                ) : (
                                    <div className={"container"}>

                                    </div>
                                )) : (
                                    <div className={"container"}>
                                        Vous n'avez aucune discussion récente
                                    </div>
                                )}

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        {pageState.page > 1 && (
                                            <li className="page-item">
                                                <a onClick={(event) => changePage(event, pageState.page - 1)}
                                                   className="page-link"
                                                   href={"#"}
                                                   aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>)}

                                        {pageState.pages && pageState.pages.map((currentPage, index) => (
                                            <li key={index}
                                                className={pageState.page === currentPage ? "page-item active" : "page-item"}>
                                                <a onClick={(event) => changePage(event, currentPage)}
                                                   className="page-link"
                                                   href={"#"}>{currentPage}</a>
                                            </li>
                                        ))}

                                        {pageState.page < discussionState.lastPage && (

                                            <li className="page-item">
                                                <a onClick={(event) => changePage(event, pageState.page + 1)}
                                                   className="page-link"
                                                   href={"#"}
                                                   aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>

                                        )}

                                    </ul>
                                </nav>


                                {users && users.length !== 0 && (<div className={"container"}>
                                    Démarrez une nouvelle conversation :
                                </div>)}

                                {users && users.filter(user => user.pseudo.toLowerCase().includes(form.recherche.toLowerCase())).map((u, index) => (
                                    <div className={"apercu-conv"} key={index}>
                                        <Link to={"/" + u.id}  style={{width: "15%"}}>
                                            <img className={"user-pp"}
                                                 src={u.picture ? process.env.REACT_APP_ADDRESS + "img/avatar/" + u.picture : noPic}
                                                 alt={"Photo"}/>
                                        </Link>
                                        <span onClick={async () => {
                                            await createConv(u.id)
                                        }} style={{cursor: "pointer"}}
                                              className={"user-pseudo"}>{u.pseudo}</span>

                                    </div>))}

                            </div>


                        </div>


                        <Spinner style={{marginTop: "60px", marginLeft: "50px"}} id={"wait-fetch"} animation="border"
                                 role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>




                        <div id={"conversation-div"} style={{width: "100%"}}>
                            <div className={"mobile-nav-chat"}>
                                {isMobile && (
                                    <li className="page-item">
                                        <a onClick={seeHistoryConv} className="page-link" href={"#"}
                                           aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>)}

                                {discussionState.userConv && (
                                    <div className={"user-conv"}>
                                        <Link to={"/" + discussionState.userConv.id}
                                              style={{height: "fit-content"}}>
                                            <img className={"user-pp-nav"}
                                                 src={discussionState.userConv.picture ? process.env.REACT_APP_ADDRESS + "img/avatar/" + discussionState.userConv.picture : noPic}
                                                 alt={"Photo"}/>
                                        </Link>
                                        <h5 style={{ marginLeft: 5 }}>{discussionState.userConv.pseudo} - <span style={{ fontWeight: 'normal' }}>{discussionState.userConv.surname} {discussionState.userConv.lastname}</span></h5>
                                    </div>
                                )}
                            </div>


                            <div className={"conv-active"}>


                                <div className={"message-box"} id={'message-box'}>

                                    {chat && chat.length !== 0 ? chat.map((c, index) => (

                                        <div key={index}
                                             className={c.message.creator.id !== jwtDecode(getCookie('token')).id ? "bubble-sender" : "bubble-mine"}>
                                            <p className={c.message.creator.id !== jwtDecode(getCookie('token')).id ? "message-content" : "message-content mine"}>
                                                {c.message.message}
                                            </p>
                                            <small style={{color: "gray", margin: '1%'}}>{moment(c.eventDate).format('LT')}</small>
                                        </div>)) : (<div>
                                        Aucun message
                                    </div>)}
                                </div>

                                <div className="input-group write-bar p-3">

                                    <input type="text"
                                           id="form1"
                                           name="writeBar"
                                           required="required"
                                           className="form-control rounded"
                                           value={form.writeBar}
                                           placeholder="Écrire un message"
                                           onChange={handleChange}
                                           onKeyDown={async (key) => {
                                               if (key.code === 'Enter') {
                                                   await sendMessage(discussionState.convId)
                                               }
                                           }}
                                    />
                                    <button type={"button"} className="btn btn-outline-primary" style={{ color: '#29abe2', borderColor: '#29abe2' }} onClick={async () => {
                                        await sendMessage(discussionState.convId)
                                    }}>
                                        <i className={"fa fa-paper-plane"}></i>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }

        </div>)
}
export default Chat
