import React, {useContext, useEffect, useState} from "react"
import moment from "moment"
import noPic from '../assets/nopic.jpg'
import '../css/admin.scss'
import '../css/app.scss'
import '../css/UserInfo.css'
import GestionContext from "../context/GestionContext";

const UserCard = ({user}) => {
    const {currentUser} = React.useContext(GestionContext)

    return (
        <div className="card mb-3 mt-3" style={{ borderColor: currentUser?.id === user.id ? '#29abe2' : 'inherit', borderWidth: 2 }}>
            <div className="row g-0">
                <div className="col-lg-4">
                    <div className="user-avatar w-100 h-100"
                         style={{
                             backgroundImage: user.picture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + user.picture + "')" : "url(" + noPic + ")",
                         }}>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="card-body">
                        <h5 className="card-title fw-bold">{user.pseudo}</h5>
                        <p className="card-text">
                            <i className="fad fa-badge-check"></i> {user.isValidated ? "Email vérifié" : "Email non vérifié"}
                            <br/>
                            <i className="far fa-id-card text-secondary me-1"></i> {user.surname} {user.lastname}
                            <br/>
                            <i className="fas fa-map-marker-alt text-secondary me-1"></i> {user.zipCode}
                        </p>
                        <p className="card-text"><small
                            className="float-end mb-1 text-muted">Inscrit
                            le {moment(user.registrationDate).format("DD/MM/YYYY")}</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserCard
