import React, {useEffect, useState} from "react"
import {Link, Navigate, useSearchParams} from "react-router-dom"
import moment from "moment"
import '../css/UserInfo.css'
import Pagination from "./Pagination"
import {setLogged} from "../redux/usersSlice"
import {useDispatch, useSelector} from "react-redux"
import {Spinner} from "react-bootstrap"
import {getBugs} from "../functions/FetchFunctions";

const Bugs = (props) => {
    const [bugs, setBugs] = useState([])

    const [params, setParams] = useSearchParams()
    const [lastPage, setLastPage] = useState(0)
    const [pages, setPages] = useState([])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(1)

    const logged = useSelector((state) => state.users.logged)
    const dispatch = useDispatch()

    const fetchBugs = async () => {
        if (document.getElementById("list-bugs")) {
            document.getElementById("list-bugs").style.display = "none"
            document.getElementById("wait-fetch-bugs").style.display = "block"
        }
        const response = await getBugs(page)
        if (response.status === 401) {
            dispatch(setLogged(false))
        } else {
            dispatch(setLogged(true))
            const json = await response.json()

            if (props.limite) {
                setBugs(json["hydra:member"].filter(b => b.subject === "Bug").slice(0, props.limite))
            }
            else {
                setBugs(json["hydra:member"].filter(b => b.subject === "Bug"))
                setLastPage(1)
            }

            if (document.getElementById("list-bugs")) {
                document.getElementById("list-bugs").style.display = "block"
                document.getElementById("wait-fetch-bugs").style.display = "none"
            }
        }
    }

    useEffect(() => {
        if (params.get('page')) {
            setPage(Number(params.get('page')))
            setNextPage(Number(params.get('page')) + 1)
        }
    }, [params])

    useEffect(() => {
        setPages(Array.from({length: lastPage}, (_, i) => i + 1))
    }, [lastPage])

    useEffect(() => {
        setBugs([])
        fetchBugs().then()
    }, [page])

    return (
        <div style={{margin: "5%", marginTop: "56px"}}>
            <div className="container">
                {
                    !logged && (
                        <Navigate to="/login"/>
                    )
                }

                <Pagination page={page} pages={pages} nextPage={nextPage} lastPage={lastPage} url={"/bugs"}/>

                <div className="card border-0 p-1 mb-3">
                    <div className="card-header bg-white text-danger fw-bold">
                        <i className="fas fa-bug"></i> Bugs
                    </div>

                    <Spinner id={"wait-fetch-bugs"} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>

                    <div id={"list-bugs"} className="card-body">
                        {bugs && bugs.map((bug, index) => (
                            <div key={index} className="d-flex mb-3">
                                <div className="rect user-avatar"
                                     style={{
                                         backgroundImage: bug.user.picture ? "url('" + process.env.REACT_APP_ADDRESS + "img/avatar/" + bug.user.picture + "')" : "url('img/nopic.jpg')",
                                     }}></div>
                                <div className="notif-content flex-grow-1 p-1 ps-2 pe-2">
                                    <p>
                                        <b><Link  className={"text-reset text-decoration-none"}
                                                 to={"/" + bug.user.id}>{bug.user.pseudo}</Link></b><br/>
                                        {bug.comment && (<span className="fst-italic">
                                        {bug.comment}</span>)}
                                    </p>
                                    <p className="mobile-date"><small className="text-muted">Le
                                        {moment(bug.date).format("DD/MM/YYYY")}
                                    </small></p>
                                </div>
                                <div className="top-right-date float-end text-muted">
                                    <small>Le {moment(bug.date).format("DD/MM/YYYY")}</small>
                                </div>
                            </div>
                        ))}
                        {bugs.length === 0 && (
                            <p className="text-muted">Aucun bug n'a été signalé pour le moment.</p>
                        )}
                        {props.limite && (
                            <div className="float-end mt-3">
                                <p>
                                    <Link className="see" to="/bugs"><small className="text-muted">Tout voir <i
                                        className="fas fa-angle-right"></i></small></Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bugs
