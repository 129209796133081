import React, {useEffect} from "react";
import {Navigate} from "react-router-dom"
import {useDispatch} from "react-redux";
import {setLogged} from "../redux/usersSlice";
import {setCookie} from "react-use-cookie";
import {tokenExpired} from "../global";

const Logout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        setCookie('token',tokenExpired)
        dispatch(setLogged(false))
        document.cookie = ''
    }, [])

    return (
        <div>
            Déconnexion
            <Navigate to={"/login"}/>
        </div>
    )
}
export default Logout