import React, {useEffect, useState} from "react"
import '../../css/Kpi.css'
import {PieChart} from "react-minimal-pie-chart"
import {getCookie} from "react-use-cookie"
import Chart from 'react-apexcharts'
import {Navigate} from "react-router-dom"
import {departements, regions, userRegion} from "../../regions"
import {useMediaQuery} from "react-responsive"
import {useSelector} from "react-redux"
import jwtDecode from "jwt-decode"
import {trancheAge, trancheAges} from "../../global"
import moment from "moment"
import {hasPermissionsTo} from "../../functions/rolesFunctions";

const KPIUsers = () => {
    const users = useSelector((state) => state.users.value)
    const logged = useSelector((state) => state.users.logged)
    const [regionState, setRegionState] = useState({options: {}, series: []})

    const [ageState, setAgeState] = useState({options: {}, series: []})
    const ageCount = []
    const userRegionCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const isMobile = useMediaQuery({query: "(max-width: 450px)"})

    const [forbidden, setForbidden] = useState(false)

    useEffect(() => {
        if (document.cookie.length !== 0 && jwtDecode(getCookie('token')).roles && !hasPermissionsTo("ROLE_KPI")) {
            setForbidden(true)
        }
    }, [])

    useEffect(() => {
        matchUserRegion()
        matchUserAge()
    }, [users])

    const matchUserAge = () => {
        let usersHorsFacebook = users.filter(u => !u.facebookUser)

        for (let i = 0; i < trancheAge.length - 1; i++) {
            ageCount.push(usersHorsFacebook.filter(u => trancheAge[i] <= moment().year() - moment(u.birthdate).year() && moment().year() - moment(u.birthdate).year() < trancheAge[i + 1]).length)
        }

        setAgeState({
            options: {
                chart: {
                    id: 'porportion-age'
                },
                xaxis: {
                    categories: trancheAges
                }
            },
            series: [{
                name: 'Proportion par tranches d\'âge',
                data: ageCount
            }]
        })
    }

    const matchUserRegion = () => {
        let i = 0
        users.map(user => {
            // Incrémentation du nombre d'utilisateurs pour la région trouvée
            for (i = 0; i < regions.length; i = i + 1) {
                if (regions[i].liste.includes(departements[parseInt(user.zipCode / 1000)])) {
                    userRegionCount[i] = userRegionCount[i] + 1
                }
            }
        })

        setRegionState({
            options: {
                chart: {

                    id: 'porportion-region'
                },
                xaxis: {
                    categories: userRegion
                }
            },
            series: [{
                name: 'Proportion par régions',
                data: userRegionCount
            }]
        })
    }


    return (
        <div className={"container"} style={{marginTop: isMobile ? "112px" : "56px"}}>
            {
                !logged && (
                    <Navigate to="/login"/>
                )
            }

            {
                forbidden ? (
                    <div><p>Vous n'avez pas accès à cette page.</p>
                        <Navigate to={"/"}/>
                    </div>
                ) : (
                    <div style={{width: "100%"}}>
                        <div className={"row"}>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}>Nombre d'utilisateurs</h1>
                                <p className={"corps-vignette"}>{users.length}</p>
                            </div>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}> Proportion Hommes/Femmes</h1>
                                <p>Nb Homme : <span className={"number-span"}
                                                    style={{background: "#29abe2"}}>{users.filter(user => user.genre === "Homme").length}</span>
                                </p>
                                <p>Nb Femme : <span className={"number-span"}
                                                    style={{background: "#FFC124"}}>{users.filter(user => user.genre === "Femme").length}</span>
                                </p>
                                <p>Non précisé : <span className={"number-span"}
                                                       style={{background: "#FF4E24"}}>{users.filter(user => !user.genre).length}</span>
                                </p>
                                <PieChart style={{height: "50%", color: "white"}}
                                          data={[
                                              {
                                                  color: '#29abe2',
                                                  title: `${parseInt("" + users.filter(user => user.genre === "Homme").length / users.length * 100)}%`,
                                                  value: users.filter(user => user.genre === "Homme").length
                                              },
                                              {
                                                  color: '#FFC124',
                                                  title: `${parseInt("" + users.filter(user => user.genre === "Femme").length / users.length * 100)}%`,
                                                  value: users.filter(user => user.genre === "Femme").length
                                              },
                                              {
                                                  color: '#FF4E24',
                                                  title: `${parseInt("" + users.filter(user => !user.genre).length / users.length * 100)}%`,
                                                  value: users.filter(user => !user.genre).length
                                              }
                                          ]}
                                />

                            </div>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}>Proportion Email vérifié/Email non vérifié</h1>
                                <p>Nb vérif : <span className={"number-span"}
                                                    style={{background: "#29abe2"}}>{users.filter(user => user.isValidated).length}</span>
                                </p>
                                <p>Nb non vérif : <span className={"number-span"}
                                                        style={{background: "#FFC124"}}>{users.filter(user => !user.isValidated).length}</span>
                                </p>

                                <PieChart style={{height: "50%"}}
                                          data={[
                                              {
                                                  color: '#29abe2',
                                                  title: `${parseInt("" + users.filter(user => user.isValidated).length / users.length * 100)}%`,
                                                  value: users.filter(user => user.isValidated).length
                                              },
                                              {
                                                  color: '#FFC124',
                                                  title: `${parseInt("" + users.filter(user => !user.isValidated).length / users.length * 100)}%`,
                                                  value: users.filter(user => !user.isValidated).length
                                              }
                                          ]}
                                />
                            </div>
                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}>Proportion pro/particuliers</h1>
                                <p>Nb Pro : <span className={"number-span"}
                                                  style={{background: "#29abe2"}}> {users.filter(user => user.company).length}</span>
                                </p>
                                <p>Nb Particulier : <span className={"number-span"}
                                                          style={{background: "#FFC124"}}>{users.filter(user => !user.company).length}</span>
                                </p>
                                <PieChart style={{height: "50%"}}
                                          data={[
                                              {
                                                  color: '#29abe2',
                                                  title: `${parseInt("" + users.filter(user => user.company).length / users.length * 100)}%`,
                                                  value: users.filter(user => user.company).length
                                              },
                                              {
                                                  color: '#FFC124',
                                                  title: `${parseInt("" + users.filter(user => !user.company).length / users.length * 100)}%`,
                                                  value: users.filter(user => !user.company).length
                                              },
                                          ]}
                                />
                            </div>

                            <div className={"col-sm vignette"}>
                                <h1 className={"titre-vignette"}>Proportion de vérifiés MangoPay</h1>
                                <p>Nb vérifiés : <span className={"number-span"}
                                                       style={{background: "#29abe2"}}> {users.filter(user => user.mangopayUserId).length}</span>
                                </p>
                                <p>Nb non vérifiés : <span className={"number-span"}
                                                           style={{background: "#FFC124"}}>{users.filter(user => !user.mangopayUserId).length}</span>
                                </p>
                                <PieChart style={{height: "50%"}}
                                          data={[
                                              {
                                                  color: '#29abe2',
                                                  title: `${parseInt("" + users.filter(user => user.mangopayUserId).length / users.length * 100)}%`,
                                                  value: users.filter(user => user.mangopayUserId).length
                                              },
                                              {
                                                  color: '#FFC124',
                                                  title: `${parseInt("" + users.filter(user => !user.mangopayUserId).length / users.length * 100)}%`,
                                                  value: users.filter(user => !user.mangopayUserId).length
                                              },
                                          ]}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm vignette w-100"}>
                                <h1 className={"titre-vignette"}>Proportion par régions</h1>
                                <div className={"corps-vignette w-100"}>
                                    <Chart options={regionState.options} series={regionState.series} type="bar"
                                           width={'100%'} height={320}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm vignette w-100"}>
                                <h1 className={"titre-vignette"}>Proportion par tranche d'âge</h1>
                                <div className={"corps-vignette w-100"}>
                                    <Chart options={ageState.options} series={ageState.series} type="bar"
                                           width={"100%"} height={320}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default KPIUsers
